const GET_COMPANY = "companies";

const GET_ONE_COMPANY = "companies/";

const EDIT_COMPANY = "companies/edit/";

const ACIVATE_ACCOUNT = "companies/activate/";

const DEACTIVATE_ACCOUNT = "companies/deactivate/";

const MULTI_DELET = "companies/remove/bulk_delete";

const FILTER_USER = "companies/users/filter";

const SEARCH_COMPANIES = "companies/users/search/";

const TOTAL_COMPANIES = "companies/company/total";

export default {
  GET_COMPANY,

  GET_ONE_COMPANY,
  TOTAL_COMPANIES,
  EDIT_COMPANY,

  ACIVATE_ACCOUNT,

  DEACTIVATE_ACCOUNT,

  MULTI_DELET,

  FILTER_USER,

  SEARCH_COMPANIES,
};
