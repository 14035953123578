import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import replyURL from "../../../../utils/url/companyList/replyApplication";

//get AllUser
export const getAllReplyApplication = createAsyncThunk(
  "companyList/getAllReplyApplication",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${replyURL.GET_ALL_REPLY_APP}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneReplyApplication = createAsyncThunk(
  "companyList/getOneReplyApplication",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${replyURL.GET_ONE_REPLY_APP}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editReplyApplication = createAsyncThunk(
  "companyList/editReplyApplication",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${replyURL.EDIT_REPLY_APP}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addReplayApplication = createAsyncThunk(
  "companyList/addReplayApplication",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(replyURL.ADD_REPLY_APP, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteReplyApplication = createAsyncThunk(
  "companyList/deleteReplyApplication",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${replyURL.DELETE_REPLY_APP}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletreplyApp = createAsyncThunk(
  "companyList/MultiDeletreplyApp",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(replyURL.MULTI_DELETE_REPLY_APP, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
