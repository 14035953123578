const GET_VIP_COMPANY = "vip_companies";

const GE_ONE_VIP_COPMANY = "vip_companies/";

const EDIT_VIP_COMPANY = "vip_companies/";

const DELETE_VIP_COMPANY = "vip_companies/";

const ACIVATE_VIP_COMPNY = "vip_companies/activate/";

const DEACTIVATE_VIP_COMPANY = "vip_companies/deactivate/";

const MULTI_DELET_VIP_COMPANY = "vip_companies/remove/bulk_delete";

export default {
  GET_VIP_COMPANY,

  GE_ONE_VIP_COPMANY,

  EDIT_VIP_COMPANY,

  ACIVATE_VIP_COMPNY,

  DELETE_VIP_COMPANY,

  DEACTIVATE_VIP_COMPANY,
  MULTI_DELET_VIP_COMPANY,
};
