import { useEffect, useState } from "react";

// material-ui
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tooltip,
  FormControl,
} from "@mui/material";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// project imports
import IconButton from "components/@extended/IconButton";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets
import { DeleteFilled } from "@ant-design/icons";

// types
import { SnackbarProps } from "types/snackbar";

import { useAppDispatch } from "hooks";
import { getAllCompanies } from "store/actions/main/companies";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { getAllResumes } from "store/actions/main/packages/resumesPackages";
import { ResumeOrder } from "model/order";
import AlertResumesDelete from "./AlertResumeDelete";
import {
  addNewResumeOrder,
  editOneResumeOrder,
} from "store/actions/main/order/resumeOrder";
import { FormControlLabel } from "@mui/material";
import { CircularProgress } from "@mui/material";

// constant
const getInitialValues = (customer: ResumeOrder | null) => {
  const newCustomer = {
    user_id: "",
    resume_package_id: "",
    payment_id: "",
    status: 0,
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormResumeAdd = ({
  customer,
  id,
  closeModal,
  fetchData,
}: {
  fetchData: any;
  id: number;
  customer: ResumeOrder | null;
  closeModal: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState();
  const [Packages, setPackages] = useState();
  useEffect(() => {
    setLoading(false);
    fetchComData();
    fetchDataPackages();

    // Format the start and end dates if they are provided as Dayjs objects
  }, [dispatch]);

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    resume_package_id: Yup.string().required("Resumes Package is required"),
    status: Yup.string().required("Status is required"),
    payment_id: Yup.number().required("Payment is required"),
  });

  const [openAlert, setOpenAlert] = useState(false);
  const fetchComData = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setUserName(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const fetchDataPackages = () => {
    dispatch(getAllResumes({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setPackages(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleAlertClose = () => {
    setOpenAlert(!openAlert);
    closeModal();
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneResumeOrder({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                resume_package_id: values.resume_package_id,
                payment_id: values.payment_id,
                status: values.status === 1 ? true : false,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/editOneResumeOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Orders updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewResumeOrder({
              user_id: values.user_id,
              resume_package_id: values.resume_package_id,
              payment_id: values.payment_id,
              status: values.status === 1 ? true : false,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "order/addNewResumeOrder/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume Orders added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "some thing went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const { value, onChange, onBlur } = getFieldProps("");
  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit resume subscription details" : "Add new resume subscription"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
                  <Grid container>
                    <Grid item xs={12} sm={3}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name  <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Select company name for the vacancy order'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>                    
                    <Grid item xs={12} sm={3} mb={1}>
                    <Stack spacing={1}>
                        <InputLabel htmlFor="customer-resume_package_id">
                          Packages
                        </InputLabel>
                        <Select
                          id="customer-resume_package_id"
                          displayEmpty
                          {...getFieldProps("resume_package_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = Packages.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("resume_package_id", selectedId);
                            setFieldValue("resume_package_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("resume_package_id").value || ""}
                        >
                          {Packages &&
                            Packages.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.count}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.resume_package_id &&
                          errors.resume_package_id && (
                            <FormHelperText error>
                              {errors.resume_package_id}
                            </FormHelperText>
                          )}
                      </Stack>
                      </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-payment_id">
                          Payment   <span style={{color:'red'}}>*</span>
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Select payment type for the vacancy order'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <Select
                          id="customer-payment_id"
                          displayEmpty
                          {...getFieldProps("payment_id")}
                          onChange={(event: SelectChangeEvent<boolean>) => {
                            // Convert true to '1' and false to '0'
                            const selectedValue = event.target.value;
                            // Set the field value to the converted value
                            setFieldValue("payment_id", selectedValue);
                          }}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={1}>1</MenuItem>
                        </Select>
                        {touched.payment_id && errors.payment_id && (
                            <FormHelperText error>
                              {errors.payment_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the vacancy status (Default inactive)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id="customer-status"
                                checked={formik.values.status}
                                {...getFieldProps("status")}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setFieldValue("status", checked);
                                }}
                              />
                            }
                            label={formik.values.status ? "Active" : "Inactive"}
                          />
                        </FormControl>
                        {touched.status && errors.status && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.status}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormResumeAdd;
