const GET_BANNER_PLACE = "banner_places";

const ADD_BANNER_PLACE = "banner_places";

const GET_ONE_BANNER_PLACE = "banner_places/";

const EDIT_BANNER_PLACE = "banner_places/";

const DELETE_BANNER_PLACE = "banner_places/";

const MULTI_DELET_BANNER_PLACEE = "banner_places/remove/bulk_delete";

export default {
  GET_BANNER_PLACE,
  GET_ONE_BANNER_PLACE,
  EDIT_BANNER_PLACE,
  ADD_BANNER_PLACE,
  DELETE_BANNER_PLACE,

  MULTI_DELET_BANNER_PLACEE,
};
