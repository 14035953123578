import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import companyReportURL from "../../../../utils/url/companyList/companyReport";

//get AllUser
export const getAllCompanyReport = createAsyncThunk(
  "companyList/getAllCompanyReport",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyReportURL.GET_ALL_COMPANY_REPORT_APP}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneCompanyReport = createAsyncThunk(
  "companyList/getOneCompanyReport",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyReportURL.GET_ONE_COMPANY_REPORT_APP}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCompanyReport = createAsyncThunk(
  "companyList/editCompanyReport",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${companyReportURL.EDIT_COMPANY_REPORT_APP}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addCompanyReport = createAsyncThunk(
  "companyList/addCompanyReport",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        companyReportURL.ADD_COPMANY_REPORT_APP,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCompanyReport = createAsyncThunk(
  "companyList/deleteCompanyReport",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${companyReportURL.DELETE_COMPANY_REPORT_APP}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCompanyReport = createAsyncThunk(
  "companyList/MultiDeletCompanyReport",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        companyReportURL.MULTI_DELETE_COMPANY_REPORT_APP,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
