const GET_SKILLS = "skills";

const ADD_SKILL = "skills";

const GET_ONE_SKILL = "skills/";

const EDIT_SKILL = "skills/";

const DELETE_SKILL = "skills/";

const MULTI_DELET_SKILL = "skills/remove/bulk_delete";

export default {
  GET_SKILLS,
  ADD_SKILL,
  GET_ONE_SKILL,
  EDIT_SKILL,
  DELETE_SKILL,
  MULTI_DELET_SKILL,
};
