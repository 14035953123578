import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MailResponse, MailState } from "model/basicInfo";

import {
  getAllAdminNote,
  getOneNote,
  addNewNote,
  deleteNote,
  editNote,
  MultiDeletnotes,
} from "store/actions/main/adminNote";

const initialState: MailState = {
    result: [],
    isOk: false,
    message: "",
    confirmCode: "",
};

const AdminNoteSlice = createSlice({
  name: "basicInfo",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllAdminNote.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllAdminNote.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewNote.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewNote.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneNote.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneNote.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editNote.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editNote.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteNote.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteNote.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletnotes.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletnotes.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = AdminNoteSlice.actions;

export default AdminNoteSlice;
