const GET_COMPANY_PROFILE = "company_profiles";

const ADD_COMPANY_PROFILE = "company_profiles";

const GET_ONE_COMPANY_PROFILE = "company_profiles/";

const EDIT_COMPANY_PROFILE = "company_profiles/";

const MULTI_DELET_PROFILE = "company_profiles/remove/bulk_delete";

const DELETE_COMPANIES = "company_profiles/";

const GET_NO_CLIENT = "company_profiles/client/NO";

const GET_YES_CLIENT = "company_profiles/client/YES";

const GET_FREE_CLIENT = "company_profiles/client/FREE";

const GET_UNDEFINED_CLIENT = "company_profiles/client/UNDEFINED";

export default {
  GET_COMPANY_PROFILE,

  ADD_COMPANY_PROFILE,

  GET_NO_CLIENT,

  GET_YES_CLIENT,

  GET_FREE_CLIENT,

  GET_UNDEFINED_CLIENT,

  GET_ONE_COMPANY_PROFILE,

  DELETE_COMPANIES,

  EDIT_COMPANY_PROFILE,

  MULTI_DELET_PROFILE,
};
