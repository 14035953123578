import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import bestPlaceURL from "../../../../utils/url/ads/bestPlace";

//get AllUser
export const getAllBestPlace = createAsyncThunk(
  "ads/getAllBestPlace",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bestPlaceURL.GET_BEST_PALCE}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneBestPlace = createAsyncThunk(
  "ads/getOneBestPlace",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${bestPlaceURL.GET_ONE_BEST_PALCE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editBestPlace = createAsyncThunk(
  "ads/editBestPlace",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${bestPlaceURL.EDIT_BEST_PALCE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addBestPlace = createAsyncThunk(
  "ads/addBestPlace",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(bestPlaceURL.ADD_BEST_PALCE, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteBestPlace = createAsyncThunk(
  "ads/deleteBestPlace",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${bestPlaceURL.DELETE_BEST_PALCE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const activeBestPlace = createAsyncThunk(
  "ads/activeBestPlace",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${bestPlaceURL.ACIVATE_BEST_PALCE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deactivateBestPlace = createAsyncThunk(
  "ads/deactivateBestPlace",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${bestPlaceURL.DEACTIVATE_BEST_PALCE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletBestPlace = createAsyncThunk(
  "ads/MultiDeletBestPlace",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        bestPlaceURL.MULTI_DELET_BEST_PALCE,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
