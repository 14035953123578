import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import questionURL from "../../../../utils/url/basicInfo/questionManagement";

//get AllUser
export const getAllQuestion = createAsyncThunk(
  "basicInfo/getAllQuestion",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${questionURL.GET_QUESTIONS}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneQuestion = createAsyncThunk(
  "basicInfo/getOneQuestion",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${questionURL.GET_ONE_QUESTIONS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editQuestion = createAsyncThunk(
  "basicInfo/editQuestion",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${questionURL.EDIT_QUESTIONS}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addQuestion = createAsyncThunk(
  "basicInfo/addQuestion",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(questionURL.ADD_QUESTIONS, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteQuestion = createAsyncThunk(
  "basicInfo/deleteQuestion",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${questionURL.DELETE_QUESTIONS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletQuestions = createAsyncThunk(
  "basicInfo/MultiDeletQuestions",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        questionURL.MULTI_DELET_QUESTIONS,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
