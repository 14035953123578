const GET_DEGREE_TYPE = "degree_types";

const ADD_DEGREE__TYPE = "degree_types";

const GET_ONE_DEGREE__TYPE = "degree_types/";

const EDIT_DEGREE__TYPE = "degree_types/";

const DELETE_DEGREE__TYPE = "degree_types/";

const MULTI_DELET_DEGREE__TYPE = "degree_types/remove/bulk_delete";

export default {
  GET_DEGREE_TYPE,
  ADD_DEGREE__TYPE,
  GET_ONE_DEGREE__TYPE,
  EDIT_DEGREE__TYPE,
  DELETE_DEGREE__TYPE,

  MULTI_DELET_DEGREE__TYPE,
};
