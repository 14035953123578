import { CardHeader,  Stack } from "@mui/material";


import { useState } from "react";


import { TableReqJob } from "./TableReqJob";




const RequiredJobSection = ({

  cvId,
  setLoading,
  loading,
  fetchGetOneReq,
  basicInfo,
}: {
  fetchGetOneReq: any;
  loading: any;
  setLoading: any;
  basicInfo: any;
  openReqJob: any;
  setOpenReqJob: (openReqJob: boolean) => void;
  cvId: any;
}) => {
  const [showTable, setShowTable] = useState(false);

console.log(showTable)


  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
          marginBottom: "10px",
        }}
      >
        <Stack className="tabs-card-resume-container" direction={"column"}>
          <CardHeader
            title="Targeted Job"
            // Add cursor pointer for indication
            className="tabs-card-resumes"
          />
          <Stack style={{ paddingLeft: "10px" }}>
            {/* Pass educationCertificates as prop to TableEducation */}
            <TableReqJob
              fetchGetOneReq={fetchGetOneReq}
              setShowTable={setShowTable}
              resumeId={cvId}
              reqJob={basicInfo}
              setLoading={setLoading}
              loading={loading}
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default RequiredJobSection;
