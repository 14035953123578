import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import educationUrl from "../../../../../utils/url/cv/education/education";
//axios

//get AllBasicInfo
export const getAllEducation = createAsyncThunk(
  "education/getAllEducation",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(educationUrl.GET_ALL_EDUCATION);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getOneEducation = createAsyncThunk(
  "education/getOneEducation",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${educationUrl.GET_ONE_EDUCATION}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editEducation = createAsyncThunk(
  "education/editEducation",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${educationUrl.EDIT_EDUCATION}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewEducation = createAsyncThunk(
  "education/addNewEducation",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(educationUrl.ADD_EDUCATION, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteEducation = createAsyncThunk(
  "education/deleteEducation",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${educationUrl.DELETE_EDUCATION}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
