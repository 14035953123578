const phoneNumber = [
    { dialCode: '+93' },
    { dialCode: '+355' },
    { dialCode: '+213' },
    { dialCode: '+376' },
    { dialCode: '+244' },
    { dialCode: '+1' },
    { dialCode: '+54' },
    { dialCode: '+374' },
    { dialCode: '+61' },
    { dialCode: '+43' },
    { dialCode: '+994' },
    { dialCode: '+1' },
    { dialCode: '+973' },
    { dialCode: '+880' },
    { dialCode: '+1' },
    { dialCode: '+375' },
    { dialCode: '+32' },
    { dialCode: '+501' },
    { dialCode: '+229' },
    { dialCode: '+975' },
    { dialCode: '+591' },
    { dialCode: '+387' },
    { dialCode: '+359' },
    { dialCode: '+226' },
    { dialCode: '+257' },
    { dialCode: '+238' },
    { dialCode: '+855' },
    { dialCode: '+237' },
    { dialCode: '+1' },
    { dialCode: '+236' },
    { dialCode: '+235' },
    { dialCode: '+56' },
    { dialCode: '+86' },
    { dialCode: '+57' },
    { dialCode: '+269' },
    { dialCode: '+242' },
    { dialCode: '+506' },
    { dialCode: '+385' },
    { dialCode: '+53' },
    { dialCode: '+357' },
    { dialCode: '+420' },
    { dialCode: '+45' },
    { dialCode: '+253' },
    { dialCode: '+1' },
    { dialCode: '+1' },
    { dialCode: '+593' },
    { dialCode: '+20' },
    { dialCode: '+503' },
    { dialCode: '+240' },
    { dialCode: '+291' },
    { dialCode: '+372' },
    { dialCode: '+251' },
    { dialCode: '+679' },
    { dialCode: '+358' },
    { dialCode: '+33' },
    { dialCode: '+241' },
    { dialCode: '+220' },
    { dialCode: '+995' },
    { dialCode: '+49' },
    { dialCode: '+233' },
    { dialCode: '+30' },
    { dialCode: '+1' },
    { dialCode: '+502' },
    { dialCode: '+224' },
    { dialCode: '+245' },
    { dialCode: '+592' },
    { dialCode: '+509' },
    { dialCode: '+504' },
    { dialCode: '+36' },
    { dialCode: '+354' },
    { dialCode: '+91' },
    { dialCode: '+62' },
    { dialCode: '+98' },
    { dialCode: '+964' },
    { dialCode: '+353' },
    { dialCode: '+972' },
    { dialCode: '+39' },
    { dialCode: '+1' },
    { dialCode: '+81' },
    { dialCode: '+962' },
    { dialCode: '+7' },
    { dialCode: '+254' },
    { dialCode: '+686' },
    { dialCode: '+383' },
    { dialCode: '+965' },
    { dialCode: '+996' },
    { dialCode: '+856' },
    { dialCode: '+371' },
    { dialCode: '+961' },
    { dialCode: '+266' },
    { dialCode: '+231' },
    { dialCode: '+218' },
    { dialCode: '+423' },
    { dialCode: '+370' },
    { dialCode: '+352' },
    { dialCode: '+389' },
    { dialCode: '+261' },
    { dialCode: '+265' },
    { dialCode: '+60' },
    { dialCode: '+960' },
    { dialCode: '+223' },
    { dialCode: '+356' },
    { dialCode: '+692' },
    { dialCode: '+222' },
    { dialCode: '+230' },
    { dialCode: '+52' },
    { dialCode: '+691' },
    { dialCode: '+373' },
    { dialCode: '+377' },
    { dialCode: '+976' },
    { dialCode: '+382' },
    { dialCode: '+212' },
    { dialCode: '+258' },
    { dialCode: '+95' },
    { dialCode: '+264' },
    { dialCode: '+674' },
    { dialCode: '+977' },
    { dialCode: '+31' },
    { dialCode: '+64' },
    { dialCode: '+505' },
    { dialCode: '+227' },
    { dialCode: '+234' },
    { dialCode: '+850' },
    { dialCode: '+47' },
    { dialCode: '+968' },
    { dialCode: '+92' },
    { dialCode: '+680' },
    { dialCode: '+970' },
    { dialCode: '+507' },
    { dialCode: '+675' },
    { dialCode: '+595' },
    { dialCode: '+51' },
    { dialCode: '+63' },
    { dialCode: '+48' },
    { dialCode: '+351' },
    { dialCode: '+974' },
    { dialCode: '+40' },
    { dialCode: '+7' },
    { dialCode: '+250' },
    { dialCode: '+1' },
    { dialCode: '+1' },
    { dialCode: '+1' },
    { dialCode: '+685' },
    { dialCode: '+378' },
    { dialCode: '+239' },
    { dialCode: '+966' },
    { dialCode: '+221' },
    { dialCode: '+381' },
    { dialCode: '+248' },
    { dialCode: '+232' },
    { dialCode: '+65' },
    { dialCode: '+421' },
    { dialCode: '+386' },
    { dialCode: '+677' },
    { dialCode: '+252' },
    { dialCode: '+27' },
    { dialCode: '+82' },
    { dialCode: '+211' },
    { dialCode: '+34' },
    { dialCode: '+94' },
    { dialCode: '+249' },
    { dialCode: '+597' },
    { dialCode: '+268' },
    { dialCode: '+46' },
    { dialCode: '+41' },
    { dialCode: '+963' },
    { dialCode: '+886' },
    { dialCode: '+992' },
    { dialCode: '+255' },
    { dialCode: '+66' },
    { dialCode: '+670' },
    { dialCode: '+228' },
    { dialCode: '+676' },
    { dialCode: '+1' },
    { dialCode: '+216' },
    { dialCode: '+90' },
    { dialCode: '+993' },
    { dialCode: '+688' },
    { dialCode: '+256' },
    { dialCode: '+380' },
    { dialCode: '+971' },
    { dialCode: '+44' },
    { dialCode: '+1' },
    { dialCode: '+598' },
    { dialCode: '+998' },
    { dialCode: '+678' },
    { dialCode: '+39' },
    { dialCode: '+58' },
    { dialCode: '+84' },
    { dialCode: '+967' },
    { dialCode: '+260' },
    { dialCode: '+263' }
];

export default phoneNumber;
