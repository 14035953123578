import { useEffect, useState } from "react";

// material-ui

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  FormHelperText,
  InputLabel,

  MenuItem,

  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Tooltip,
 
  FormControl,
} from "@mui/material";

// third-party
import _ from "lodash";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider } from "formik";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// project imports

import CircularWithPath from "components/@extended/progress/CircularWithPath";

import { openSnackbar } from "api/snackbar";

// assets


// types
import { SnackbarProps } from "types/snackbar";
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

import { useAppDispatch } from "hooks";


import { getAllCompanies } from "store/actions/main/companies";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ResumeSubSecription } from "model/subscription";
import { getAllResumes } from "store/actions/main/packages/resumesPackages";
import {
  addNewResSub,
  editOneResumeSub,
} from "store/actions/main/subscription/resumeSub";
import { getAllResumeOrder } from "store/actions/main/order/resumeOrder";
import { FormControlLabel } from "@mui/material";
import { CircularProgress } from "@mui/material";

// constant
const getInitialValues = (customer: ResumeSubSecription | null) => {
  const newCustomer = {
    user_id: "",
    resume_package_id: "",
    amount_resumes: "",
    start_date: "",
    end_date: "",
    status: false,
    resume_order_id: "",
    used_resumes: "",
  };
  if (customer) {
    return _.merge({}, newCustomer, customer);
  }

  return newCustomer;
};

// ==============================|| CUSTOMER ADD / EDIT - FORM ||============================== //

const FormResumeAdd = ({
  customer,
  id,
  fetchData,
  closeModal,
}: {
  id: number;
  fetchData: any;
  customer: ResumeSubSecription | null;
  closeModal: () => void;
}) => {

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState<any>();
  const [Packages, setPackages] = useState<any>();
  const [order, setOrder] = useState(null);

  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  useEffect(() => {
    setLoading(false);
    fetchComData();
    fetchDataPackages();
    fetchOrderData();

    // Format the start and end dates if they are provided as Dayjs objects
    if (customer) {
      const formattedStartDate = customer.start_date
        ? dayjs(customer.start_date).format("YYYY-MM-DD")
        : null;
      const formattedEndDate = customer.end_date
        ? dayjs(customer.end_date).format("YYYY-MM-DD")
        : null;
      setStartDate(formattedStartDate);
       // @ts-ignore
      setEndDate(formattedEndDate);
    } else {
      // If customer data doesn't exist, set default values for start and end dates
      setStartDate(null);
      setEndDate(null);
    }
  }, [dispatch]);

  const CustomerSchema = Yup.object().shape({
    user_id: Yup.string().required("Company Name is required"),
    resume_package_id: Yup.string().required("Resumes Package is required"),
    amount_resumes: Yup.number().required("Amount Resumes is required"),
    status: Yup.string().required("Status is required"),
    // end_date: Yup.string().required("End Date is required"),
    //start_date: Yup.number().required("Start Date is required"),
    resume_order_id: Yup.number().required("Resume Order is required"),
    used_resumes: Yup.number().required("Used Resumes is required"),
  });

  const fetchComData = () => {
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setUserName(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchOrderData = () => {
    dispatch(getAllResumeOrder({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setOrder(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDataPackages = () => {
    dispatch(getAllResumes({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setPackages(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
 
  const handleDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setStartDate(formattedDate); // Update the state directly with the formatted date
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    const formattedDate = date ? date.format("YYYY-MM-DD") : null;
    setEndDate(formattedDate); // Update the state directly with the formatted date
  };

  const formik = useFormik({
    initialValues: getInitialValues(customer!),
    validationSchema: CustomerSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (customer) {
          // Dispatch editCategories action if customer exists
          await dispatch(
            editOneResumeSub({
              id: customer.id || 0,
              data: {
                user_id: values.user_id,
                resume_package_id: values.resume_package_id,
                amount_resumes: values.amount_resumes,
                status: values.status === true ? true : false,
                resume_order_id: values.resume_order_id,
                used_resumes: values.used_resumes,
                start_date: startDate,
                end_date: endDate,
              },
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "sub/editOneResumeSub/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume SubScription updated successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "something went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        } else {
          // Dispatch create newCat action if no customer exists
          await dispatch(
            addNewResSub({
              user_id: values.user_id,
              resume_package_id: values.resume_package_id,
              amount_resumes: values.amount_resumes,
              status: values.status === true ? true : false,
              resume_order_id: values.resume_order_id,
              used_resumes: values.used_resumes,
              start_date: startDate,
              end_date: endDate,
            }),
          ).then((action) => {
            fetchData();
            if (action.type === "sub/addNewResSub/fulfilled") {
              openSnackbar({
                open: true,
                message: "Resume subscription added successfully",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "success",
                },
              } as SnackbarProps);
              closeModal();
            } else {
              openSnackbar({
                open: true,
                message: "something went wrong",
                anchorOrigin: { vertical: "top", horizontal: "right" },
                variant: "alert",
                alert: {
                  color: "error",
                },
              } as SnackbarProps);
              closeModal();
            }
          });
        }
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;
  console.log(errors);

  if (loading)
    return (
      <Box sx={{ p: 5 }}>
        <Stack direction="row" justifyContent="center">
          <CircularWithPath />
        </Stack>
      </Box>
    );

  return (
    <>
      <FormikProvider value={formik}>
        <LocalizationProvider>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
              {customer ? "Edit resume subscription" : "Add new resume subscription"}
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ p: 2.5 }}>
                  <Grid container>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-resume_order_id">
                          Order
                        </InputLabel>
                        <Select
                          id="customer-resume_order_id"
                          displayEmpty
                          {...getFieldProps("resume_package_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = order.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("resume_order_id", selectedId);
                            setFieldValue("resume_order_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("resume_order_id").value || ""}
                        >
                          {order &&
                            order.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.id}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.resume_order_id && errors.resume_order_id && (
                          <FormHelperText error>
                            {errors.resume_order_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-user_id">
                          Company Name
                        </InputLabel>
                        <Select
                          id="customer-user_id"
                          displayEmpty
                          {...getFieldProps("user_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedUser = userName.find(
                              (user) => user.id === selectedId,
                            );
                            const selectedUserName = selectedUser
                              ? selectedUser.user_name
                              : "";
                            setFieldValue("user_id", selectedId);
                            setFieldValue("user_name", selectedUserName); // Set user name value to another field
                          }}
                          value={getFieldProps("user_id").value || ""}
                        >
                          {userName &&
                            userName.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.user_name}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.user_id && errors.user_id && (
                          <FormHelperText error>
                            {errors.user_id}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={3} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-resume_package_id">
                          Packages
                        </InputLabel>
                        <Select
                          id="customer-resume_package_id"
                          displayEmpty
                          {...getFieldProps("resume_package_id")}
                          onChange={(event: SelectChangeEvent<string>) => {
                            const selectedId = event.target.value;
                            const selectedPackage = Packages.find(
                              (pkg: any) => pkg.id === selectedId,
                            );
                            const selectedType = selectedPackage
                              ? selectedPackage.id
                              : "";
                            setFieldValue("resume_package_id", selectedId);
                            setFieldValue("resume_package_id", selectedType); // Set type value to another field
                          }}
                          value={getFieldProps("resume_package_id").value || ""}
                        >
                          {Packages &&
                            Packages.map((pkg: any) => (
                              <MenuItem key={pkg.id} value={pkg.id}>
                                {pkg.id}
                              </MenuItem>
                            ))}
                        </Select>
                        {touched.resume_package_id &&
                          errors.resume_package_id && (
                            <FormHelperText error>
                              {errors.resume_package_id}
                            </FormHelperText>
                          )}
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-used_resumes">
                          Used
                        </InputLabel>
                        <TextField
                          fullWidth
                          id="customer-used_resumes"
                          placeholder="Enter Used Resumes"
                          {...getFieldProps("used_resumes")}
                          error={Boolean(
                            touched.used_resumes && errors.used_resumes,
                          )}
                          helperText={
                            touched.used_resumes && errors.used_resumes
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-amount_resumes">
                          Amount
                        </InputLabel>
                        <TextField
                          fullWidth
                          type="number"
                          id="customer-amount_resumes"
                          placeholder="Enter Amount Resumes"
                          {...getFieldProps("amount_resumes")}
                          error={Boolean(
                            touched.amount_resumes && errors.amount_resumes,
                          )}
                          helperText={
                            touched.amount_resumes && errors.amount_resumes
                          }
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-start_date">
                          From
                        </InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={startDate ? dayjs(startDate) : null}
                            onChange={(date) => handleDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid md={1}/>
                    <Grid item xs={12} sm={5} mb={1}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-end_date">To</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            value={endDate ? dayjs(endDate) : null}
                            onChange={(date) => handleEndDateChange(date)}
                          />
                        </LocalizationProvider>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={12}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-status">
                          Status
                          <span style={{color:'grey', marginLeft:'5px'}}>
                            <Tooltip title={'Set the vacancy status (Default inactive)'}>
                              <HelpOutlineOutlinedIcon style={{width:'15px', height:'15px', cursor:'pointer'}}/>
                            </Tooltip>
                          </span>
                        </InputLabel>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id="customer-status"
                                checked={formik.values.status}
                                {...getFieldProps("status")}
                                onChange={(event) => {
                                  const checked = event.target.checked;
                                  setFieldValue("status", checked);
                                }}
                              />
                            }
                            label={formik.values.status ? "Active" : "Inactive"}
                          />
                        </FormControl>
                        {touched.status && errors.status && (
                          <FormHelperText
                            error
                            id="standard-weight-helper-text-email-login"
                            sx={{ pl: 1.75 }}
                          >
                            {errors.status}
                          </FormHelperText>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
            </DialogContent>
            <Divider />           
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" variant="contained">
                        {isSubmitting? <CircularProgress size="1.5rem" style={{color:'white'}}/> : 'Submit'}
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={closeModal}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
          </Form>
        </LocalizationProvider>
      </FormikProvider>
    </>
  );
};

export default FormResumeAdd;
