import { CardHeader, Stack } from "@mui/material";


import { TableQuestion } from "./TableQuestion";

const QuestionSection = ({
  cvId,
  basicInfo,
  setLoading,
  loading,


  fetchGetOneQues,
}: {
  fetchGetOneQues: any;
  cvId: any;
  basicInfo: any;
  setLoading: any;
  loading: any;
  openAnswre: any;
  setOpenAnswer: (open: boolean) => void;
}) => {
 

  return (
    <>
      <Stack
        direction={"row"}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginRight: "17px",
        }}
      >
        <Stack direction={"column"}>
          <CardHeader title="Mini Interview" />
          <Stack style={{ paddingLeft: "10px" }}>
            <TableQuestion
              cvId={cvId}
              coursCertificates={basicInfo}
              setLoadingQues={setLoading}
              loadingQues={loading}
              fetchGetOneQues={fetchGetOneQues}
            />
          </Stack>
        </Stack>
        {/* Other education-related fields */}
      </Stack>
    </>
  );
};

export default QuestionSection;
