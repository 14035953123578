const GET_ALL_RECOMMENDATIONS = "resume/recommendations";

const ADD_RECOMMENDATIONS = "resume/recommendations";

const EDIT_RECOMMENDATIONS = "resume/recommendations/";

const GET_ONE_RECOMMENDATIONS = "resume/recommendations/";

const DELETE_RECOMMENDATIONS = "resume/recommendations/";

const MULTI_DELET_RECOMMENDATIONS = "resume/recommendations/remove/bulk_delete";

export default {
  GET_ALL_RECOMMENDATIONS,

  ADD_RECOMMENDATIONS,

  EDIT_RECOMMENDATIONS,

  GET_ONE_RECOMMENDATIONS,

  DELETE_RECOMMENDATIONS,

  MULTI_DELET_RECOMMENDATIONS,
};
