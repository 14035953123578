import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS } from "api";
import responseError from "utils/api/error-handling";
import languageURL from "../../../../utils/url/basicInfo/language";

//get AllUser
export const getAllLanguage = createAsyncThunk(
  "basicInfo/getAllLanguage",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await AXIOS.get(
        `${languageURL.GET_LANGUAGE}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneLanguage = createAsyncThunk(
  "basicInfo/getOneLanguage",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await AXIOS.get(`${languageURL.GET_ONE_LANGUAGE}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editLanguage = createAsyncThunk(
  "basicInfo/editLanguage",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await AXIOS.post(
        `${languageURL.EDIT_LANGUAGE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addLanguage = createAsyncThunk(
  "basicInfo/addLanguage",
  async (data: any, thunkAPI) => {
    try {
      const response = await AXIOS.post(languageURL.ADD_LANGUAGE, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deletLanguage = createAsyncThunk(
  "basicInfo/deletLanguage",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(
        `${languageURL.DELETE_LANGUAGE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletLanguage = createAsyncThunk(
  "basicInfo/MultiDeletLanguage",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await AXIOS.delete(languageURL.MULTI_DELET_LANGUAGE, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
