import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import resumeSubURL from "../../../../utils/url/subscription/resumeSubscription";

//get AllCategories
export const getAllResumesSub = createAsyncThunk(
  "sub/getAllResumesSub",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${resumeSubURL.GET_RESUMES_SUB}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One Category

export const getOneResumesSub = createAsyncThunk(
  "sub/getOneResumesSub",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${resumeSubURL.GET_ONE_RESUMES_SUB}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editOneResumeSub = createAsyncThunk(
  "sub/editOneResumeSub",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${resumeSubURL.EDIT_RESUMES_SUB}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New Category
export const addNewResSub = createAsyncThunk(
  "sub/addNewResSub",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(resumeSubURL.ADD_RESUMES_SUB, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete Category

export const deleteResumeSub = createAsyncThunk(
  "sub/deleteResumeSub",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${resumeSubURL.DELETE_RESUMESSUB}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletResumeSub = createAsyncThunk(
  "sub/MultiDeletResumeSub",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(resumeSubURL.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const FilterResumeSub = createAsyncThunk(
  "sub/FilterResumeSub",
  async (
    {
      start_date,
      end_date,
    }: {
      start_date?: Date | null;
      end_date?: Date | null;
    },
    thunkAPI,
  ) => {
    try {
      const data = {
        start_date,
        end_date,
      };
      const response = await USERAXIOS.post(
        resumeSubURL.FILTER_RESUMES_SUB,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
