import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import vacancytURL from "../../../../utils/url/vacaciens/jobActivities";

//get AllUser
export const getAllJobActivities = createAsyncThunk(
  "vacancy/getAllVacancy",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacancytURL.GET_VACANCIES}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER



//EDIT ONE USER

