const GET_ALL_EXPERIENCE = "resume/experiences";

const ADD_EXPERIENCE = "resume/experiences";

const EDIT_EXPERIENCE = "resume/experiences/";

const GET_ONE_EXPERIENCE = "resume/experiences/";

const DELETE_EXPERIENCE = "resume/experiences/";

const MULTI_DELET_EXPERIENCE = "resume/experiences/remove/bulk_delete";

export default {
  GET_ALL_EXPERIENCE,
  ADD_EXPERIENCE,

  EDIT_EXPERIENCE,

  GET_ONE_EXPERIENCE,
  DELETE_EXPERIENCE,
  MULTI_DELET_EXPERIENCE,
};
