import { useState } from "react";
// material-ui
import {
 
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  TextField,
  FormControlLabel,
  Checkbox,
  Divider,
  DialogActions,
} from "@mui/material";

import * as Yup from "yup";

// project import
import { openSnackbar } from "api/snackbar";
import { useAppDispatch } from "hooks";

import { SnackbarProps } from "types/snackbar";
import { addNewSkillsResumes } from "store/actions/main/cv/skill/skill";
import TextArea from "antd/es/input/TextArea";

// types
const validationSchema = Yup.object().shape({
  skill_name: Yup.string().required("Name is required"),
  skill_level: Yup.string().required("Skill Level is required"),
  experience_years: Yup.string().required("Experience Years Level is required"),
  last_used: Yup.string().required("Last Used is required"),
});
function SkillsDialog({
  cvId,
  setLoading,
  loading,
  fetchGetOneSkill,
  openSkills,
  setOpenSkills,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneSkill: any;
  cvId: any;
  openSkills: boolean;
  setOpenSkills: (openLanguage: boolean) => void;
}) {
  const handleClose = () => {
    setOpenSkills(false);
  };

  const [skillData, setSkillData] = useState({
    resume_id: 0,
    skill_name: "",
    skill_level: "",
    experience_years: "",
    last_used: "",
    until_now: "",
    description: "",
  });

  const [selectedLevel, setSelectedLevel] = useState("");
  const dispatch = useAppDispatch();
  const [untilNow, setUntilNow] = useState(false);

  const handleUntillNowhange = (event) => {
    setUntilNow(event.target.checked); // Set the value of confidentiality based on checkbox state
  };

  const [formErrors, setFormErrors] = useState({
    resume_id: 0,
    skill_name: "",
    skill_level: "",
    experience_years: "",
    last_used: "",
    until_now: "",
    description: "",
  });
  const validate = async () => {
    try {
      // Combine all data to be validated
      const dataToValidate = {
        ...skillData,
        resume_id: cvId,
        skill_level: selectedLevel,
        until_now: setUntilNow,
      };

      // Validate the combined data against the validation schema
      await validationSchema.validate(dataToValidate, { abortEarly: false });
      setFormErrors({
        resume_id: 0,
        skill_name: "",
        skill_level: "",
        experience_years: "",
        last_used: "",
        until_now: "",
        description: "",
      });
      return true; // Return true if validation succeeds
    } catch (errors:any) {
      // If validation fails, extract error messages
      const errorMessages = {
        resume_id: 0,
        skill_name: "",
        skill_level: "",
        experience_years: "",
        last_used: "",
        until_now: "",
        description: "",
      };
      errors.inner.forEach((error) => {
        errorMessages[error.path] = error.message;
      });
      setFormErrors(errorMessages); // Set error messages for each field
      return false;
    }
  };
  const handleSave = async () => {
    // Log average value for debugging

    const isValid = await validate();
    if (isValid) setLoading(true);
    dispatch(
      addNewSkillsResumes({
        resume_id: cvId,
        skill_level: selectedLevel,
        skill_name: skillData.skill_name,
        last_used: skillData.last_used,
        description: skillData.description,
        experience_years: skillData.experience_years,
        until_now: setUntilNow,
      }),
    ).then((action) => {
      if (action.type === "skills/addNewSkillsResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Skill Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setLoading(false);
        fetchGetOneSkill();
        setOpenSkills(false);
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpenSkills(false);
        setLoading(false);
      }
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    // Convert the value to a string if it's not already
    const processedValue = name === "last_used" ? String(value) : value;
    setSkillData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  // Convert the array to a comma-separated string
  // Output the string
  // Convert the array to a comma-separated string
  // Output the string

  return (
    <Dialog open={openSkills} onClose={handleClose}>
      
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>Skill</DialogTitle>
       <Divider /> 

      <DialogContent>
       
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-skill_name">
                  Skill Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-skill_name"
                  value={skillData.skill_name}
                  name="skill_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Skill Name"
                  autoFocus
                  //inputRef={inputRef}
                />
                {formErrors.skill_name && (
                  <FormHelperText error>{formErrors.skill_name}</FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-reading_level">Level</InputLabel>
                <Select
                  id="reading_level"
                  value={selectedLevel} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedLevel(event.target.value)} // Handle the change event
                >
                  <MenuItem value={"BEGINNER"}>BEGINNER</MenuItem>
                  <MenuItem value={"INTERMEDIATE"}>INTERMEDIATE</MenuItem>
                  <MenuItem value={"INTERMEDIATE"}>INTERMEDIATE</MenuItem>
                </Select>
                {formErrors.skill_level && (
                  <FormHelperText error>
                    {formErrors.skill_level}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-experience_years">
                  Years of Experience
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-experience_years"
                  value={skillData.experience_years}
                  name="experience_years"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Experience Years"
                  autoFocus
                  type="number"
                  //inputRef={inputRef}
                />
                {formErrors.experience_years && (
                  <FormHelperText error>
                    {formErrors.experience_years}
                  </FormHelperText>
                )}
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="last_used">Last Used</InputLabel>
                  <Select
                    fullWidth
                    id="last_used"
                    style={{ width: "200px" }}
                    value={skillData.last_used}
                    onChange={(e:any)=>handleInputChange(e)}
                    name="last_used"
                    placeholder="Last Used"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.last_used && (
                    <FormHelperText error>
                      {formErrors.last_used}
                    </FormHelperText>
                  )}
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={untilNow}
                        onChange={handleUntillNowhange}
                        id="stillWorking-checkbox"
                      />
                    }
                    label="stillWorking"
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-description">
                  Description
                </InputLabel>
                <TextArea
                  id="personal-description"
                  value={skillData.description}
                  name="description"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Description"
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
          </Grid>
          
      
      </DialogContent>
      <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      Add Skill
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default SkillsDialog;
