import { createAsyncThunk } from '@reduxjs/toolkit'




//axios
import { USERAXIOS } from '../../../api'

//urls
import authenticationURL from '../../../utils/url/authentication'

//error message
import responseError from '../../../utils/api/error-handling'
import { LoginModel } from '../../../model/sign'



//login
export const login = createAsyncThunk(
  'authentication/login',
  async (data: LoginModel, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(authenticationURL.LOGIN_URL, data)
      console.log(response.data)
      return response.data
    } catch (error: any) {
      return thunkAPI.rejectWithValue(responseError(error))
    }
  }
)

//logout
export const logout = createAsyncThunk(
  'authentication/logout',
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(authenticationURL.LOGOUT_URL)
      return response.data.message
    } catch (error: any) {
      return thunkAPI.rejectWithValue(responseError(error))
    }
  }
)
