import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PackagesResumes, PackagesResumesState } from "model/packages";
import {
  MultiDeletdeleteResumes,
  addNewResumePacakges,
  deleteResume,
  editOneResume,
  getAllResumes,
  getOneResume,
} from "store/actions/main/packages/resumesPackages";

const initialState: PackagesResumesState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const resumePackageSlice = createSlice({
  name: "packages",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllResumes.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllResumes.fulfilled.type,
        (state, action: PayloadAction<PackagesResumes[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewResumePacakges.fulfilled.type,
        (state, action: PayloadAction<PackagesResumes[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewResumePacakges.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneResume.fulfilled.type,
        (state, action: PayloadAction<PackagesResumes[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneResume.fulfilled.type,
        (state, action: PayloadAction<PackagesResumes[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteResume.fulfilled.type,
        (state, action: PayloadAction<PackagesResumes[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletdeleteResumes.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletdeleteResumes.fulfilled.type,
        (state, action: PayloadAction<PackagesResumes[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = resumePackageSlice.actions;

export default resumePackageSlice;
