const GET_ALL_REPLY_APP = "reply_applicants";

const GET_ONE_REPLY_APP = "reply_applicants/";

const EDIT_REPLY_APP = "reply_applicants/";

const ADD_REPLY_APP = "reply_applicants";

const DELETE_REPLY_APP = "reply_applicants/";

const MULTI_DELETE_REPLY_APP = "reply_applicants/remove/bulk_delete";

export default {
  GET_ONE_REPLY_APP,
  GET_ALL_REPLY_APP,
  EDIT_REPLY_APP,
  ADD_REPLY_APP,
  DELETE_REPLY_APP,
  MULTI_DELETE_REPLY_APP,
};
