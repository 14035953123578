import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CVResponse, CVState } from "model/cv/basicInfo/basicInfo";
import {
  ReadResume,
  UnReadResume,
  activeCv,
  addNewBasicInfo,
  deactivateCv,
  deleteBasicInfo,
  editOneBasicInfo,
  getAllActiveResume,
  getAllBasicInfo,
  getAllInActiveResume,
  getOnebasicInfo,
  totalALLCV,
} from "store/actions/main/cv/basicinfo/basicInfo";

const initialState: CVState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const basicInfoSlice = createSlice({
  name: "resumes",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllBasicInfo.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllBasicInfo.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewBasicInfo.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewBasicInfo.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOnebasicInfo.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOnebasicInfo.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneBasicInfo.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneBasicInfo.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteBasicInfo.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteBasicInfo.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        activeCv.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        activeCv.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deactivateCv.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deactivateCv.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        totalALLCV.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        totalALLCV.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getAllActiveResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllActiveResume.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getAllInActiveResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllInActiveResume.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        ReadResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        ReadResume.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        UnReadResume.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        UnReadResume.fulfilled.type,
        (state, action: PayloadAction<CVResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = basicInfoSlice.actions;

export default basicInfoSlice;
