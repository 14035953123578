const GET_ALL_TITLES = "titles";

const ADD_TITLES = "titles";

const GET_ONE_TITLES = "titles/";

const EDIT_TITLES = "titles/";

const DELETE_TITLES = "titles/";

const MULTI_DELET_TITLES = "titles/remove/bulk_delete";

export default {
  GET_ALL_TITLES,
  GET_ONE_TITLES,
  ADD_TITLES,
  EDIT_TITLES,
  DELETE_TITLES,
  MULTI_DELET_TITLES,
};
