const GET_ALL_REPORTS = "report_candidates";

const ADD_REPORT = "report_candidates";

const GET_ONE_REPORT = "report_candidates/";

const EDIT_REPORT = "report_candidates/";

const DELETE_REPORT = "report_candidates/";

const MULTI_DELET_REPORTS = "report_candidates/remove/bulk_delete";

export default {
  GET_ALL_REPORTS,
  GET_ONE_REPORT,
  ADD_REPORT,
  EDIT_REPORT,
  DELETE_REPORT,

  MULTI_DELET_REPORTS,
};
