import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  FilterUsers,
  MultiDeletUsers,
  SearchUsers,
  activeAccount,
  addNewUser,
  deactivateAccount,
  deleteUser,
  editUser,
  getAllUsers,
  getOneUser,
  totalUsers,
} from "../../actions/main/users";
import { Userstate, JobSeekerResponse } from "model/user"; // Import JobSeekerResponse

const initialState: Userstate = {
  result: [], // Initialize as an empty array
  isOk: false,
  message: "",
  confirmCode: "",
  adduser: {
    user_name: "",
    account_language: "",
    password: "",
    account_status: "",
    account_type: "",
    email: "",
    mobile_number: "",
  },
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllUsers.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllUsers.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewUser.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewUser.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneUser.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneUser.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editUser.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editUser.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteUser.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteUser.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        activeAccount.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        activeAccount.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deactivateAccount.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deactivateAccount.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletUsers.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletUsers.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        FilterUsers.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        FilterUsers.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        SearchUsers.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        SearchUsers.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        totalUsers.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        totalUsers.fulfilled.type,
        (state, action: PayloadAction<JobSeekerResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export default userSlice;
