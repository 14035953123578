const LOGIN_URL = "user_register/login";

const LOGOUT_URL = "logout";

const VERIFY_CODE_URL = "confirm-phone";


export default {
   
    LOGIN_URL,
    LOGOUT_URL,
    VERIFY_CODE_URL,
   
}