import { ReactNode, useEffect, useState } from 'react';

// third-party
import { IntlProvider, MessageFormatElement } from 'react-intl';

// project import
import useConfig from '../hooks/useConfig';

import messages_en from '../../src/utils/locales/en.json';
import messages_ar from '../../src/utils/locales/ar.json';
import { I18n } from '../types/config';

// load locales files
const loadLocaleData = (locale: I18n) => {
 
    switch (locale) {
     
      case 'ar':
        return import('../utils/locales/ar.json');
      case 'en':
      default:
        return import('../utils/locales/en.json');
    }}


// ==============================|| LOCALIZATION ||============================== //

interface Props {
  children: ReactNode;
}

const Locales = ({ children }: Props) => {
  const { i18n } = useConfig();
  const messages1 = {
    'ar': messages_ar,
    'en':messages_en
  
    
  };
  
  
  const language = 'ar';


  // useEffect(() => {
  //   loadLocaleData(i18n).then((d: { default: Record<string, string> | Record<string, MessageFormatElement[]> | undefined }) => {
  //     setMessages(d.default);
  //   });
  // }, [i18n]);
console.log(i18n,"local 18n");

  return (
    <>
     
        <IntlProvider locale={i18n} defaultLocale='en' messages={messages1[i18n]}>
          {children}
        </IntlProvider>
      
    </>
  );
};

export default Locales;
