import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";


//get AllUser

//GET ONE USER

export const getAllPages = createAsyncThunk(
  "pages/getAllPages",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/pages?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const getOnePage = createAsyncThunk(
  "page/getOnePage",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/pages/${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const editPage = createAsyncThunk(
  "Page/editPage",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `https://test.syriajob.com/public/api/admin/pages/${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER


export const addStaticPage = createAsyncThunk(
  "pages/addPage",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post("https://test.syriajob.com/public/api/admin/pages", data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);


