import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  WorkExperienceState,
  WorkExperienceResponse,
} from "model/cv/workExperience/workExperience";
import {
  addNewExperience,
  deleteExperience,
  editExperience,
  getAllExperience,
  getOneExperience,
} from "store/actions/main/cv/experience/experience";

const initialState: WorkExperienceState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const experienceSlice = createSlice({
  name: "experience",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state:any) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllExperience.rejected.type,
        (state:any, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllExperience.fulfilled.type,
        (state:any, action: PayloadAction<WorkExperienceResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewExperience.fulfilled.type,
        (state:any, action: PayloadAction<WorkExperienceResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewExperience.rejected.type,
        (state:any, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneExperience.fulfilled.type,
        (state:any, action: PayloadAction<WorkExperienceResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneExperience.rejected.type,
        (state:any, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editExperience.rejected.type,
        (state:any, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editExperience.fulfilled.type,
        (state:any, action: PayloadAction<WorkExperienceResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteExperience.rejected.type,
        (state:any, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteExperience.fulfilled.type,
        (state:any, action: PayloadAction<WorkExperienceResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = experienceSlice.actions;

export default experienceSlice;
