const GET_ALL_TARGET_JOB = "resume/target_jobs";

const ADD_TARGET_JOB = "resume/target_jobs";

const EDIT_TARGET_JOB = "resume/target_jobs/";

const GET_ONE_TARGET_JOB = "resume/target_jobs/";

const DELETE_ARGET_JOB = "resume/target_jobs/";

const MULTI_DELET_TARGET_JOB = "resume/target_jobs/remove/bulk_delete";

export default {
  GET_ALL_TARGET_JOB,

  GET_ONE_TARGET_JOB,

  EDIT_TARGET_JOB,

  ADD_TARGET_JOB,

  DELETE_ARGET_JOB,

  MULTI_DELET_TARGET_JOB,
};
