import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import actionsURL from "../../../../utils/url/basicInfo/commentAction";

//get AllUser
export const getAllActionsComment = createAsyncThunk(
  "basicInfo/getAllActionsComment",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${actionsURL.GET_ALL_COMMENT_ACTIONS}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneCommentActions = createAsyncThunk(
  "basicInfo/getOneCommentActions",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${actionsURL.GET_ONE_COMMENT_ACTIONS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCommentActions = createAsyncThunk(
  "basicInfo/editCommentActions",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${actionsURL.EDIT_COMMENT_ACTIONS}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addCommentActions = createAsyncThunk(
  "basicInfo/addCommentActions",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        actionsURL.ADD_COMMENT_ACTIONS,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCommentActions = createAsyncThunk(
  "basicInfo/deleteCommentActions",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${actionsURL.DELETE_COMMENT_ACTIONS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCommentActions = createAsyncThunk(
  "basicInfo/MultiDeletCommentActions",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        actionsURL.MULTI_DELET_COMMENT_ACTIONS,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
