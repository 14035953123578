const GET_ALL_COMPANY_REPLY_APP = "company_replies";

const GET_ONE_COMPANY_REPLY_APP = "company_replies/";

const EDIT_COMPANY_REPLY_APP = "company_replies/";

const ADD_COPMANY_REPLY_APP = "company_replies";

const DELETE_COMPANY_REPLY_APP = "company_replies/";

const MULTI_DELETE_COMPANY_REPLY_APP = "company_replies/remove/bulk_delete";

export default {
  GET_ALL_COMPANY_REPLY_APP,
  GET_ONE_COMPANY_REPLY_APP,
  EDIT_COMPANY_REPLY_APP,
  ADD_COPMANY_REPLY_APP,
  DELETE_COMPANY_REPLY_APP,
  MULTI_DELETE_COMPANY_REPLY_APP,
};
