const GET_JOBAVA_TYPE = "job_availabilities";

const ADD_JOBAVA___TYPE = "job_availabilities";

const GET_ONE_JOBAVA__TYPE = "job_availabilities/";

const EDIT_JOBAVA___TYPE = "job_availabilities/";

const DELETE_JOBAVA__TYPE = "job_availabilities/";

const MULTI_DELET_JOBAVA___TYPE = "job_availabilities/remove/bulk_delete";

export default {
  GET_JOBAVA_TYPE,
  ADD_JOBAVA___TYPE,
  GET_ONE_JOBAVA__TYPE,
  EDIT_JOBAVA___TYPE,
  DELETE_JOBAVA__TYPE,

  MULTI_DELET_JOBAVA___TYPE,
};
