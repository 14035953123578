import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Country, CountryState, ParentCountry } from "model/country";
import {
  GetChildCountries,
  MultiDeletCountries,
  ParentCountries,
  SearchCountries,
  addNewCountry,
  deleteCountry,
  editCountry,
  getAllCountries,
  getOneCountry,
} from "store/actions/main/countries";

const initialState: CountryState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
  country: [],
  childCities: [],
};

const countrySlice = createSlice({
  name: "country",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCountries.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllCountries.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewCountry.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewCountry.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneCountry.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneCountry.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editCountry.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editCountry.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteCountry.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteCountry.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletCountries.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletCountries.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        SearchCountries.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        SearchCountries.fulfilled.type,
        (state, action: PayloadAction<Country[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        ParentCountries.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        ParentCountries.fulfilled.type,
        (state, action: PayloadAction<ParentCountry[]>) => {
          state.country = action.payload;
        },
      )
      .addCase(
        GetChildCountries.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        GetChildCountries.fulfilled.type,
        (state, action: PayloadAction<ParentCountry[]>) => {
          state.country = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = countrySlice.actions;

export default countrySlice;
