import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  GetChildCategories,
  MultiDeletCategories,
  ParentCategories,
  SearchCategories,
  addNewCategory,
  deleteCategory,
  editOneCategory,
  getAllCategories,
  getOneCategory,
} from "../../actions/main/categories";
import { Category, CategoryState, ParentCat } from "model/categories";

const initialState: CategoryState = {
  result: [],
  parent: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const categorySlice = createSlice({
  name: "category",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllCategories.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllCategories.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewCategory.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewCategory.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneCategory.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneCategory.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneCategory.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editOneCategory.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteCategory.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteCategory.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletCategories.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletCategories.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        SearchCategories.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        SearchCategories.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        ParentCategories.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        ParentCategories.fulfilled.type,
        (state, action: PayloadAction<ParentCat[]>) => {
          state.parent = action.payload;
        },
      )
      .addCase(
        GetChildCategories.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        GetChildCategories.fulfilled.type,
        (state, action: PayloadAction<Category[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = categorySlice.actions;

export default categorySlice;
