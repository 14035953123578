const GET_CONTRIES = "countries";

const GET_COUNTRY = "countries/";

const MULTI_DELET = "countries/remove/bulk_delete";

const SEARCH_COUNTRIES = "countries/country/search/";

const GET_PARENT_COUNTRY = "countries/country/parents";

const CHILD_COUNTRY = "countries/country/childs/";

export default {
  GET_CONTRIES,

  GET_COUNTRY,

  MULTI_DELET,

  SEARCH_COUNTRIES,

  GET_PARENT_COUNTRY,

  CHILD_COUNTRY,
};
