import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import skillUrl from "../../../../../utils/url/cv/skills/skills";
//axios

//get AllBasicInfo
export const getAllSkillsResumes = createAsyncThunk(
  "skills/getAllSkillsResumes",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(skillUrl.GET_ALL_SKILLS);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getSkillsResumes = createAsyncThunk(
  "skills/getSkillsResumes",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(`${skillUrl.GET_ONE_SKILL}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editSkillsResumes = createAsyncThunk(
  "skills/editSkillsResumes",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${skillUrl.EDIT_SKILLS}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewSkillsResumes = createAsyncThunk(
  "skills/addNewSkillsResumes",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(skillUrl.ADD_SKILLS, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteSkillsResumes = createAsyncThunk(
  "skills/deleteSkillsResumes",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(`${skillUrl.DELETE_SKILL}${id}`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
