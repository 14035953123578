const GET_ROLES = "roles";

const ADD_ROLE = "roles";

const DELETE_ROLE = "roles/";

const GET_ROLE = "roles/";

const EDIT_ROLE = "roles/";

const PERMISSIONS = "roles/permissions";

export default {
  GET_ROLES,

  ADD_ROLE,

  DELETE_ROLE,

  GET_ROLE,

  PERMISSIONS,

  EDIT_ROLE,
};
