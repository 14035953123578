import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import vacancySubURL from "../../../../utils/url/subscription/vacancySubscription";

//get AllCategories
export const getAllVacanciesSub = createAsyncThunk(
  "sub/getAllVacanciesSub",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacancySubURL.GET_VACANCIES_SUB}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One Category

export const getOneVacancySub = createAsyncThunk(
  "sub/getOneVacancySub",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacancySubURL.GET_ONE_VACANCIES_SUB}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editOneVacancySub = createAsyncThunk(
  "sub/editOneVacancySub",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${vacancySubURL.EDIT_VACANCIES_SUB}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New Category
export const addNewVacancySub = createAsyncThunk(
  "sub/addNewVacancySub",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        vacancySubURL.ADD_VACANCIES_SUB,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete Category

export const deleteVacancySub = createAsyncThunk(
  "sub/deleteVacancySub",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${vacancySubURL.DELETE_VACANCIES_SUB}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletVacancySub = createAsyncThunk(
  "sub/MultiDeletVacancySub",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(vacancySubURL.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const FilterVacancySub = createAsyncThunk(
  "sub/FilterVacancySub",
  async (
    {
      start_date,
      end_date,
      type,
      package_validation,
    }: {
      start_date?: Date | null;
      end_date?: Date | null;
      type?: any;
      package_validation?: any;
    },
    thunkAPI,
  ) => {
    try {
      const data = {
        start_date,
        end_date,
        type,
        package_validation,
      };
      const response = await USERAXIOS.post(
        vacancySubURL.FILTER_VACANCY_SUB,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
