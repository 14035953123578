import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";


//get AllUser
export const getAllVacancy = createAsyncThunk(
  "vacancy/getAllVacancy",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/vacancies?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneVacancy = createAsyncThunk(
  "vacancy/getOneVacancy",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `https://test.syriajob.com/public/api/admin/vacancies/${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editVacancy = createAsyncThunk(
  "vacancy/editVacancy",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `https://test.syriajob.com/public/api/admin/vacancies/${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addVacancy = createAsyncThunk(
  "vacancy/addVacancy",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post("https://test.syriajob.com/public/api/admin/vacancies", data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteVacancy = createAsyncThunk(
  "vacancy/deleteVacancy",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `https://test.syriajob.com/public/api/admin/vacancies/${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeleteVacancy = createAsyncThunk(
  "vacancy/MultiDeleteVacancy",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        "https://test.syriajob.com/public/api/admin/vacancies",
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
