import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import vacancyOrderURL from "../../../../utils/url/orders/vacancyOrder";

//get AllCategories
export const getAllVacanciesOrder = createAsyncThunk(
  "order/getAllVacanciesOrder",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacancyOrderURL.GET_VACANCIES_ORDERS}?page=${pageNumber}`,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One Category

export const getOneVacancyOrder = createAsyncThunk(
  "order/getOneVacancyOrder",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacancyOrderURL.GET_ONE_VACANCIES_ORDERS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editOneVacancyOrder = createAsyncThunk(
  "order/editOneVacancyOrder",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${vacancyOrderURL.EDIT_VACANCIES_ORDERS}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New Category
export const addNewVacancyOrder = createAsyncThunk(
  "order/addNewVacancyOrder",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        vacancyOrderURL.ADD_VACANCIES_ORDERS,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete Category

export const deleteVacancyOrder = createAsyncThunk(
  "order/deleteVacancyOrder",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${vacancyOrderURL.DELETE_VACANCIES_ORDERS}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletVacancyOrder = createAsyncThunk(
  "order/MultiDeletVacancyOrder",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(vacancyOrderURL.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const FilterVacancyOrder = createAsyncThunk(
  "sub/FilterVacancySub",
  async (
    {
      display_in_best_place,
      display_in_app,
      display_logo,
      created_at_start,
      created_at_end,
      status,
    }: {
      created_at_start?: Date | null;
      created_at_end?: Date | null;
      status?: string;
      display_in_best_place: string;
      display_in_app?: string;
      display_logo?: string;
    },
    thunkAPI,
  ) => {
    try {
      const data = {
        display_logo,
        display_in_best_place,
        display_in_app,

        created_at_start,
        created_at_end,
        status,
      };
      const response = await USERAXIOS.post(
        vacancyOrderURL.FILTER_VACANCY_ORDER,
        data,
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
