import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import languagenUrl from "../../../../../utils/url/cv/language/languages";
//axios

//get AllBasicInfo
export const getAllLanguagesResumes = createAsyncThunk(
  "languages/getAllLanguagesResumes",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(languagenUrl.GET_ALL_LANGUAGES);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getLanguagesResumes = createAsyncThunk(
  "languages/getLanguagesResumes",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${languagenUrl.GET_ONE_LANGUAGES}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editLanguagesResumes = createAsyncThunk(
  "languages/editLanguagesResumes",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${languagenUrl.EDIT_LANGUAGES}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewLanguagesResumes = createAsyncThunk(
  "languages/addNewLanguagesResumes",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(languagenUrl.ADD_LANGUAGES, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteLanguagesResumes = createAsyncThunk(
  "languages/deleteLanguagesResumes",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${languagenUrl.DELETE_LANGUAGES}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
