import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import answerURL from "../../../../utils/url/basicInfo/answer";

//get AllUser
export const getAllAnswer = createAsyncThunk(
  "basicInfo/getAllAnswer",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${answerURL.GET_ANSWER}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneAnswer = createAsyncThunk(
  "basicInfo/getOneAnswer",
  async ({ id }: { id: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${answerURL.GET_ONE_GET_ANSWER}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editAnswer = createAsyncThunk(
  "basicInfo/editAnswer",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${answerURL.EDIT_ANSWER}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addAnswer = createAsyncThunk(
  "basicInfo/addAnswer",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(answerURL.ADD_ANSWER, data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteAnswer = createAsyncThunk(
  "basicInfo/deleteAnswer",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${answerURL.DELETE_GET_ANSWER}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletAnswer = createAsyncThunk(
  "basicInfo/MultiDeletAnswer",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        answerURL.MULTI_DELET_GET_ANSWER,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
