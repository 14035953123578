import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import coursersURL from "../../../../../utils/url/cv/course/course";
//axios

//get AllBasicInfo
export const getAllCoursers = createAsyncThunk(
  "course/getAllCoursers",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(coursersURL.GET_ALL_COURSERS);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getOneCourse = createAsyncThunk(
  "course/getOneCourse",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${coursersURL.GET_ONE_COURSE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editCourse = createAsyncThunk(
  "course/editCourse",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${coursersURL.EDIT_COURSE}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewCourse = createAsyncThunk(
  "course/addNewCourse",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(coursersURL.ADD_COURSE, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteCourse = createAsyncThunk(
  "course/deleteCourse",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${coursersURL.DELETE_COURSE}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
