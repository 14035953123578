import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import majorCompanyURL from "../../../../utils/url/ads/majorCompany";

//get AllUser
export const getAllMajorCompany = createAsyncThunk(
  "ads/getAllMajorCompany",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${majorCompanyURL.GET_VIP_COMPANY}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneMajorCompany = createAsyncThunk(
  "ads/getOneMajorCompany",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${majorCompanyURL.GE_ONE_VIP_COPMANY}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editMajorCompany = createAsyncThunk(
  "ads/editMajorCompany",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${majorCompanyURL.EDIT_VIP_COMPANY}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addMajorCompany = createAsyncThunk(
  "ads/addMajorCompany",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        majorCompanyURL.GET_VIP_COMPANY,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteMajorCompany = createAsyncThunk(
  "ads/deleteMajorCompany",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${majorCompanyURL.DELETE_VIP_COMPANY}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const activeMajorCompany = createAsyncThunk(
  "ads/activeMajorCompany",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${majorCompanyURL.ACIVATE_VIP_COMPNY}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deactivateMajorCompany = createAsyncThunk(
  "ads/deactivateMajorCompany",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${majorCompanyURL.DEACTIVATE_VIP_COMPANY}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletMajorCompany = createAsyncThunk(
  "ads/MultiDeletMajorCompany",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        majorCompanyURL.MULTI_DELET_VIP_COMPANY,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
