import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import { FormattedMessage } from "react-intl";
const useVacacinesItem = () => {
  const icons = {
    CategoryOutlinedIcon,
    LocationCityOutlinedIcon,
  };

  const vacacinesItems = {
    id: "group-Jobs",
    title: (
      <FormattedMessage id="Jobs">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "lisofvacancies",
        title: <FormattedMessage id="lisofvacancies" />,
        type: "item",
        url: "/list-of-vacancies",
        icon: icons.CategoryOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "deactivatedsoon",
        title: <FormattedMessage id="deactivatedsoon" />,
        type: "item",
        url: "/deactivated-soon",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },

      {
        id: "vacanciespackagesendssoon",
        title: <FormattedMessage id="vacanciespackagesendssoon" />,
        type: "item",
        url: "/vacancies-packages-ends-soon",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "classifieds",
        title: <FormattedMessage id="classifieds" />,
        type: "item",
        url: "/classifieds",
        icon: icons.LocationCityOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };

  return vacacinesItems;
};

export default useVacacinesItem;
