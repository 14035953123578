
// material-ui
import {

  Box,
  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,

  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAppDispatch } from "hooks";

import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";

import { editAnswer } from "store/actions/main/basicInfo/answer";


// types

function EditQuestionDialog({
  setLoadingQues,
  loadingQues,
  fetchDataQues,
  resumeId,
  cvId,
  open,
  setOpen,
  courseData,
  setCourseData,
}: {
  setLoadingQues: any;
  loadingQues: any;
  fetchDataQues: any;
  resumeId: any;
  cvId: any;
  open: any;
  setOpen: (open: boolean) => void;
  courseData: any;
  setCourseData: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const dispatch = useAppDispatch();


  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setCourseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async () => {
    // Log average value for debugging
    setLoadingQues(true);
    dispatch(
      editAnswer({
        id: cvId,
        data: {
          resume_id: resumeId,
          resume_mini_interview_question_id:
            courseData?.resume_mini_interview_question_id || "",
          answer: courseData?.answer,
        },
      }),
    ).then((action) => {
      if (action.type === "basicInfo/editAnswer/fulfilled") {
        openSnackbar({
          open: true,
          message: "Answer Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoadingQues(false);
        fetchDataQues();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoadingQues(false);
      }
    });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <Stack
        direction={"row"}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <DialogTitle style={{ fontWeight: "bold" }}>Mini Interview</DialogTitle>
        <CloseIcon
          style={{
            marginTop: "12px",
            marginRight: "20px",
            width: "20px",
            height: "20px",
            cursor: "pointer",
          }}
          onClick={() => setOpen(false)}
        />
      </Stack>

      <DialogContent>
        <Box sx={{ p: 2.5 }} width={"50vw"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-language_id">Question</InputLabel>
                <Typography>{courseData?.question_text || ""}</Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-answer">Answer</InputLabel>
                <TextField
                  fullWidth
                  id="personal-answer"
                  value={courseData?.answer || ""}
                  name="answer"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Answer"
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
          </Grid>
          <Stack display={"flex"} alignItems={"end"} marginTop={"20px"}>
            <Button type="submit" variant="contained" onClick={handleSave}>
              EDIT
            </Button>
          </Stack>
        </Box>
      </DialogContent>
    </Dialog>
  );
}

export default EditQuestionDialog;
