const GET_ALL_COMPANY_DESCRIPTION =
  "resume/experiences/company/company_description";

const GET_ONE_COMPANY_DESCRIPTION =
  "resume/experiences/company/company_description/";

const EDIT_COMPANY_DESCRIPTION =
  "resume/experiences/company/company_description/";

const ADD_COPMANY_DESCRIPTION = "company_replies";

const DELETE_COMPANY_DESCRIPTION = "company_replies/";

const MULTI_DELETE_COMPANY_DESCRIPTION = "company_replies/remove/bulk_delete";

export default {
  GET_ALL_COMPANY_DESCRIPTION,
  GET_ONE_COMPANY_DESCRIPTION,
  EDIT_COMPANY_DESCRIPTION,
  ADD_COPMANY_DESCRIPTION,
  DELETE_COMPANY_DESCRIPTION,
  MULTI_DELETE_COMPANY_DESCRIPTION,
};
