const GET_ALL_COMPANY_REPORT_APP = "company_reports";

const GET_ONE_COMPANY_REPORT_APP = "company_reports/";

const EDIT_COMPANY_REPORT_APP = "company_reports/";

const ADD_COPMANY_REPORT_APP = "company_reports";

const DELETE_COMPANY_REPORT_APP = "company_reports/";

const MULTI_DELETE_COMPANY_REPORT_APP = "company_reports/remove/bulk_delete";

export default {
  GET_ALL_COMPANY_REPORT_APP,

  GET_ONE_COMPANY_REPORT_APP,

  EDIT_COMPANY_REPORT_APP,

  ADD_COPMANY_REPORT_APP,
  DELETE_COMPANY_REPORT_APP,
  MULTI_DELETE_COMPANY_REPORT_APP,
};
