import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import companyDescriptiontURL from "../../../../utils/url/companyList/companyDescription";

//get AllUser
export const getAllCompanyDescription = createAsyncThunk(
  "companyList/getAllCompanyDescription",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        companyDescriptiontURL.GET_ALL_COMPANY_DESCRIPTION,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneCompanyDescription = createAsyncThunk(
  "companyList/getOneCompanyDescription",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${companyDescriptiontURL.GET_ONE_COMPANY_DESCRIPTION}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editCompanyDescription = createAsyncThunk(
  "companyList/editCompanyDescription",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${companyDescriptiontURL.EDIT_COMPANY_DESCRIPTION}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addCompanyDescription = createAsyncThunk(
  "companyList/addCompanyDescription",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        companyDescriptiontURL.ADD_COPMANY_DESCRIPTION,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteCompanyDescription = createAsyncThunk(
  "companyList/deleteCompanyDescription",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${companyDescriptiontURL.DELETE_COMPANY_DESCRIPTION}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletCompanyDescription = createAsyncThunk(
  "companyList/MultiDeletCompanyDescription",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        companyDescriptiontURL.MULTI_DELETE_COMPANY_DESCRIPTION,
        {
          data: { ids },
        },
      );
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
