// material-ui
import {
    Button,
    Dialog,
    DialogContent,
    Stack,
    Grid,
    Typography,
    InputLabel,
    
    FormControl,
    MenuItem
  } from "@mui/material";
  
  // project import
  import Avatar from "components/@extended/Avatar";
  import { PopupTransition } from "components/@extended/Transitions";
  
  //import { deleteCustomer } from 'api/customer';
  import { openSnackbar } from "api/snackbar";
  
  // assets
  import { DeleteFilled } from "@ant-design/icons";
  
  // types
  import { SnackbarProps } from "types/snackbar";
  import { useAppDispatch } from "hooks";
  
  import { deleteBasicInfo } from "store/actions/main/cv/basicinfo/basicInfo";
import { Select } from "@mui/material";
import { getAllAdminNote } from "store/actions/main/adminNote";
import { useEffect } from "react";
  
  interface Props {
    id: string;
    title?: string;
    open?: boolean;
    handleClose?: () => void;
    fetchData?: any;
  }
  
  // ==============================|| CUSTOMER - DELETE ||============================== //
  
  export default function ResumeSendNote({
    id,
    title,
    open,
    handleClose,
    fetchData,
  }: Props) {
    const dispatch = useAppDispatch();
    const handleDelete = () => {
        // @ts-ignore
      dispatch(deleteBasicInfo({ cvId: id })).then((action) => {
        fetchData();
        if (action.type === "resumes/deleteBasicInfofulfilled") {
          handleClose();
          openSnackbar({
            open: true,
            message: "Resume deleted successfully",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            variant: "alert",
            alert: {
              color: "success",
            },
          } as SnackbarProps);
          handleClose();
        }
      });
    };


useEffect(() => {
    dispatch(getAllAdminNote({pageNumber:1})).then((action)=>{
        console.log(action.payload,"action payload");
        const { data } = action.payload;
        console.log(data,"data");
        
        }) 
}, [dispatch]);
  
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        keepMounted
        TransitionComponent={PopupTransition}
        maxWidth="xs"
        aria-labelledby="column-delete-title"
        aria-describedby="column-delete-description"
      >
        <DialogContent sx={{ mt: 2, my: 1 }}>
          <Stack alignItems="center" spacing={3.5}>
            <Avatar
              color="error"
              sx={{ width: 72, height: 72, fontSize: "1.75rem" }}
            >
              <DeleteFilled />
            </Avatar>
            <Stack spacing={2}>
              <Typography variant="h4" align="center">
                Are you want to send note?
              </Typography>
             
            </Stack>
            <Grid item xs={12} sm={6}>
                      <Stack spacing={1}>
                        <InputLabel htmlFor="customer-email">email</InputLabel>
                        <FormControl fullWidth>
                              <Select
                                name="lang"
                                value="en"
                              
                               
                              >
                                <MenuItem value="EN">EN</MenuItem>
                                <MenuItem value="AR">AR</MenuItem>
                              </Select>
                              
                            </FormControl>
                      </Stack>
                    </Grid>
            <Stack direction="row" spacing={2} sx={{ width: 1 }}>
              <Button
                fullWidth
                onClick={handleClose}
                color="secondary"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                fullWidth
                color="error"
                variant="contained"
                onClick={handleDelete}
                autoFocus
              >
               Send
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
  