const GET_PAYMENT = "payments";

const GET_ONE_PAYMENT = "payments/";

const EDIT_PAYMENT = "payments/";

const ADD_PAYMENT = "payments";

const MULTI_DELET_PAYMENT = "payments/remove/multi_delete";

const DELET_PAYMENT = "payments/";

export default {
  GET_PAYMENT,

  GET_ONE_PAYMENT,

  EDIT_PAYMENT,

  ADD_PAYMENT,
  
  MULTI_DELET_PAYMENT,

  DELET_PAYMENT,
};
