import { createAsyncThunk } from "@reduxjs/toolkit";
import vacanciesURL from "../../../../utils/url/packages/vacancies";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
//get AllUser
export const getAllVacancies = createAsyncThunk(
  "packages/getAllVacancies",
  async ({ pageNumber }: { pageNumber: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacanciesURL.GET_VACANCIES_PACKAGES}?page=${pageNumber}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
//GET ONE USER

export const getOneVacancy = createAsyncThunk(
  "packages/getOneVacancy",
  async ({ id }: { id: string }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${vacanciesURL.GET_ONE_VACANCIES_PACKAGES}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//EDIT ONE USER

export const editOneVacancy = createAsyncThunk(
  "packages/editOneVacancy",
  async ({ id, data }: { id: number; data: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        `${vacanciesURL.EDIT_VACANCIES_PACKAGES}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
export const addNewVacancy = createAsyncThunk(
  "packages/addNewVacancy",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(
        vacanciesURL.ADD_VACANCIES_PACKAGES,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const deleteVacancy = createAsyncThunk(
  "packages/deleteVacancy",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${vacanciesURL.DELETE_VACANCIES_PACKAGES}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

export const MultiDeletdeleteVacancies = createAsyncThunk(
  "packages/MultiDeletdeleteVacancies",
  async ({ ids }: { ids: number[] }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(vacanciesURL.MULTI_DELET, {
        data: { ids },
      });
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
