const GET_ALL_COMMENT_ACTIONS = "comment_actions";

const ADD_COMMENT_ACTIONS = "comment_actions";

const GET_ONE_COMMENT_ACTIONS = "comment_actions/";

const EDIT_COMMENT_ACTIONS = "comment_actions/";

const DELETE_COMMENT_ACTIONS = "comment_actions/";

const MULTI_DELET_COMMENT_ACTIONS = "comment_actions/remove/bulk_delete";

export default {
  GET_ALL_COMMENT_ACTIONS,
  GET_ONE_COMMENT_ACTIONS,
  ADD_COMMENT_ACTIONS,
  EDIT_COMMENT_ACTIONS,
  DELETE_COMMENT_ACTIONS,

  MULTI_DELET_COMMENT_ACTIONS,
};
