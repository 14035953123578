import { useEffect, useState } from "react";

// material-ui
import {

  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  DialogActions,
  Divider,
} from "@mui/material";

import { useAppDispatch } from "hooks";
import {
  editEducation,
  getOneEducation,
} from "store/actions/main/cv/education/education";
import {
  GetChildCountries,
  ParentCountries,
} from "store/actions/main/countries";
import { getAllEducational } from "store/actions/main/basicInfo/educational";
import { getAllDegree } from "store/actions/main/basicInfo/degree";
import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";
import TextArea from "antd/es/input/TextArea";

// types

function EditEducationDialog({
  open,
  resumeId,
  cvId,
  setOpen,
  educationData,
  setEducationData,
  fetchGetOneEdu,
  loading,
  setLoading,
}: {
  fetchGetOneEdu: any;
  loading: any;
  setLoading: any;
  resumeId: any;
  cvId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  educationData: any;
  setEducationData: any;
}) {
  const fetchGetOne = () => {
    dispatch(getOneEducation({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setEducationData(data);
      setSelectedEducationInstId(
        educationData?.educatioanl_institution_id || "",
      );
      setSelectedDegreeId(educationData?.degree_type_id || "");
      setSelectedCountry(educationData?.country || "");
      setSelectedCity(educationData?.city || "");
    });
  };
  const [selectedEducationInst, setSelectedEducationInst] = useState([]);
  const [selectedEducationInstId, setSelectedEducationInstId] = useState("");
  const [selectedDegree, setSelectedDegree] = useState([]);
  const [selectedDegreeId, setSelectedDegreeId] = useState("");
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const dispatch = useAppDispatch();

  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [country, setCountry] = useState();

  const [childCities, setChildCities] = useState([]);
  const [selectedCityName, setSelectedCityName] = useState("");
  const handleCountryChange = (event) => {
    const selectedCountryId = event.target.value; // Get the selected country ID
    setSelectedCountry(selectedCountryId); // Update the selected country ID state

    // Find the selected country object
      // @ts-ignore
    const selectedCountryObject = country.find(
      (country) => country.id === selectedCountryId,
    );

    if (selectedCountryObject) {
      setSelectedCountryName(selectedCountryObject.name); // Set the selected country name

      // Set the selected city to "All Cities" if the selected country is not the first item in the list
      if (selectedCountryId == "1") {
        dispatch(GetChildCountries({ id: 1 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        }); // Disable city selection

        setSelectedCity("");
      } else {
        dispatch(GetChildCountries({ id: 2 })).then((action) => {
          const { data } = action.payload;
          setChildCities(data);
          if (data.length > 0) {
            setSelectedCity(data[0].id);
            setSelectedCityName(data[0].name);
          }
          // Set fetched child cities to state
        });
        setSelectedCity("");
      }
    }
  };

  const fetchDataPackages = () => {
    dispatch(ParentCountries())
      .then((action) => {
        const { data } = action.payload;
        // Set user names as options for autocomplete
        setCountry(data);
        // Set default selected country to the first one in the list
        if (data.length > 0) {
          setSelectedCountry(data[0].id);
          setSelectedCountryName(data[0].name);
        }
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCityChange = (event) => {
    const selectedCityId = event.target.value;
    const selectedCity = childCities.find((city) => city.id === selectedCityId);
    setSelectedCity(selectedCityId);
    // Set the selected city name
    setSelectedCityName(selectedCity?.name || "");
  };
  useEffect(() => {
    fetchDataPackages();
    dispatch(GetChildCountries({ id: 1 })).then((action) => {
      const { data } = action.payload;
      setChildCities(data);
      if (data.length > 0) {
        setSelectedCity(data[0].id);
        setSelectedCityName(data[0].name);
      }
      // Set fetched child cities to state
    });
    fetchGetOne();
  }, [dispatch]);
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setEducationData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const fetchEducationData = () => {
    dispatch(getAllEducational({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedEducationInst(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchDegreeData = () => {
    dispatch(getAllDegree({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedDegree(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editEducation({
        id: cvId,
        data: {
          resume_id: resumeId,
          certificate_name: educationData.certificate_name,
          description: educationData.description,
          educatioanl_institution_id: selectedEducationInstId,
          specialization: educationData.specialization,
          degree_type_id: selectedDegreeId,
          average: educationData.average,
          country: selectedCountryName,
          city: selectedCityName,
          graduation_year: educationData.graduation_year,
        },
      }),
    ).then((action) => {
      if (action.type === "education/editEducation/fulfilled") {
        openSnackbar({
          open: true,
          message: "Education Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setLoading(false);
        setOpen(false);
        fetchGetOneEdu();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
      }
    });
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleEducationChange = (event) => {
    const selectedEdId = event.target.value; // Get the selected ID
    setSelectedEducationInstId(selectedEdId); // Set the selected ID to state
      // @ts-ignore
    const selectedItem = selectedEducationInst.find(
      (item) => item.id === selectedEdId,
    );
    if (selectedItem) {
        // @ts-ignore
      const selectedName = selectedItem.name;
    }
  };
  const handleDegereeChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedDegreeId(selectedId); // Set the selected ID to state
      // @ts-ignore
    const selectedItem = selectedDegree.find((item) => item.id === selectedId);
    
  };
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  useEffect(() => {
    fetchEducationData();
    fetchDegreeData();
    fetchDataPackages();
  }, [dispatch]);

  return (
    <Dialog open={open} onClose={handleClose}>
     
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px' }}>Education</DialogTitle>
      <Divider />

      <DialogContent>
      
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="educatioanl_institution_id">
                  Educational Instituation
                </InputLabel>
                <Select
                  id="educatioanl_institution_id"
                  value={selectedEducationInstId} // Set the selected ID as the value of the Select component
                  onChange={handleEducationChange} // Handle the change event
                >
                  {selectedEducationInst.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-certificate_name">
                  Degree Name
                </InputLabel>
                <TextField
                  fullWidth
                  value={educationData?.certificate_name || ""}
                  onChange={handleInputChange}
                  name="certificate_name"
                  // onBlur={handleBlur}
                  //onChange={handleChange}
                  id="certificate_name"
                  placeholder="Certificate Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="degree_type_id">Degree</InputLabel>
                <Select
                  id="degree_type_id"
                  value={selectedDegreeId} // Set the selected ID as the value of the Select component
                  onChange={handleDegereeChange} // Handle the change event
                >
                  {selectedDegree.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email">Major</InputLabel>
                <TextField
                  fullWidth
                  value={educationData?.specialization || ""}
                  onChange={handleInputChange}
                  name="specialization"
                  // onBlur={handleBlur}
                  //onChange={handleChange}
                  id="specialization"
                  placeholder="Major"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
            <Stack spacing={1}>
            <InputLabel htmlFor="country">Country</InputLabel>
            <FormControl fullWidth>
                      <Select
                     
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        //  onBlur={handleBlur}
                        inputProps={{
                          name: "country",
                          id: "country",
                        }}
                      >
                        {country &&
                          // @ts-ignore
                          country.map((country: any) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
              </Stack></Grid>

              <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
              <InputLabel  htmlFor="city">
                    City
                  </InputLabel>
                  <FormControl fullWidth>
                      <Select
                        
                        labelId="city-label"
                        id="city"
                        value={selectedCity}
                        onChange={handleCityChange}
                        // onBlur={handleBlur}
                        name="city"
                      >
                        {childCities.map((city) => (
                          <MenuItem key={city.id} value={city.id}>
                            {city.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </Stack></Grid>

                <Grid item xs={12} sm={6}>
                <Stack spacing={1}>
                <InputLabel htmlFor="graduation_year">
                    Graduation Year
                  </InputLabel>
                  <Select
                      
                      fullWidth
                      id="graduation_year"
                      value={educationData?.graduation_year || ""}
                        // @ts-ignore
                      onChange={handleInputChange}
                      name="graduation_year"
                      placeholder="Graduation Year"
                    >
                      {years.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </Stack></Grid>
                  <Grid item xs={12} sm={6}>
                  <Stack spacing={1}>
                  <InputLabel
                    htmlFor="personal-date"
                   
                  >
                    Rate
                  </InputLabel>
                  <TextField
                  
                      fullWidth
                      id="average"
                      type="number"
                      value={educationData?.average || ""}
                      onChange={handleInputChange}
                      name="average"
                      //onBlur={handleBlur}
                      //onChange={handleChange}
                      placeholder="Rate"
                    />
                    </Stack></Grid>
           
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-description">
                  Description
                </InputLabel>
                <TextArea
                  value={educationData?.description || ""}
                  onChange={handleInputChange}
                  name="description"
                  rows={3}
                  // onBlur={handleBlur}
                  //onChange={handleChange}
                  id="description"
                  placeholder="Description"
                />
              </Stack>
            </Grid>
          </Grid>
         
       
      </DialogContent>
      <Divider/>
      <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                     Edit Education
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default EditEducationDialog;
