const GET_RESUME_ORDERS = "resume_orders";

const ADD_RESUME_ORDERS = "resume_orders";

const EDIT_RESUME_ORDERS = "resume_orders/";

const GET_ONE_RESUME_ORDERS = "resume_orders/";

const DELETE_RESUME_ORDERS = "resume_orders/";

const FILTER_RESUME_ORDER = "resume_orders/order/filter";

const MULTI_DELET = "resume_orders/remove/bulk_delete";

const TOTAL_CV_ORDERS = "resume_orders/order/total";

export default {
  GET_RESUME_ORDERS,
  TOTAL_CV_ORDERS,
  ADD_RESUME_ORDERS,

  EDIT_RESUME_ORDERS,

  GET_ONE_RESUME_ORDERS,

  FILTER_RESUME_ORDER,

  DELETE_RESUME_ORDERS,

  MULTI_DELET,
};
