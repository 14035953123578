import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  UserProfile,
  UserProfileState,
} from "model/cv/jobSeekerProfile/jobSeekerProfile";

import {
  addNewJobSeekerProfile,
  deleteJobSeekerProfile,
  editJobSeekerProfile,
  getAllJobSeekerProfile,
  getOneJobSeekerProfile,
} from "store/actions/main/cv/jobSeekerProfile/jobSeekerProfile";

const initialState: UserProfileState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const JobSeekerProfileSlice = createSlice({
  name: "jobSeeker",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllJobSeekerProfile.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllJobSeekerProfile.fulfilled.type,
        (state, action: PayloadAction<UserProfile[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewJobSeekerProfile.fulfilled.type,
        (state, action: PayloadAction<UserProfile[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewJobSeekerProfile.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneJobSeekerProfile.fulfilled.type,
        (state, action: PayloadAction<UserProfile[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneJobSeekerProfile.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editJobSeekerProfile.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editJobSeekerProfile.fulfilled.type,
        (state, action: PayloadAction<UserProfile[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteJobSeekerProfile.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteJobSeekerProfile.fulfilled.type,
        (state, action: PayloadAction<UserProfile[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = JobSeekerProfileSlice.actions;

export default JobSeekerProfileSlice;
