import { useEffect, useState } from "react";

// material-ui
import {

  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,


  MenuItem,

  Select,
  Checkbox,
  Divider,
  DialogActions,
} from "@mui/material";

import { useAppDispatch } from "hooks";

import TextArea from "antd/es/input/TextArea";

import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";

import {
  editSkillsResumes,
  getSkillsResumes,
} from "store/actions/main/cv/skill/skill";

// types

function EditLanguageDialog({
  open,
  cvId,
  setOpen,

  resumeId,
  setLoading,

  fetchGetOneSkill,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneSkill: any;
  resumeId: any;
  cvId: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  language: any;
  setLanguage: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();
  const [skillData, setSkillData] = useState({
    resume_id: 0,
    skill_name: "",
    skill_level: "",
    experience_years: "",
    last_used: "",
    until_now: "",
    description: "",
  });

  const [selectedLevel, setSelectedLevel] = useState("");

  const [untilNow, setUntilNow] = useState<any>(false);

  const handleUntillNowhange = (event) => {
    setUntilNow(event.target.checked); // Set the value of confidentiality based on checkbox state
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    // Convert the value to a string if it's not already
    const processedValue = name === "last_used" ? String(value) : value;
    setSkillData((prevData) => ({
      ...prevData,
      [name]: processedValue,
    }));
  };

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 90; i--) {
    years.push(i);
  }
  const fetchGetOne = () => {
    dispatch(getSkillsResumes({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setSkillData(data);
      setUntilNow(skillData?.until_now || false);
      setSelectedLevel(skillData?.skill_level || "");
    });
  };
  useEffect(() => {
    fetchGetOne();
  }, [dispatch]);
  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editSkillsResumes({
        id: cvId,
        data: {
          resume_id: resumeId,
          skill_level: selectedLevel,
          skill_name: skillData.skill_name,
          last_used: skillData.last_used,
          description: skillData.description,
          experience_years: skillData.experience_years,
          until_now: setUntilNow,
        },
      }),
    ).then((action) => {
      if (action.type === "skills/editSkillsResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Skill Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
        fetchGetOneSkill();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
      }
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
     
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px' }}>Skill</DialogTitle>
       <Divider/>

      <DialogContent>
       
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-skill_name">
                  Skill Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-skill_name"
                  value={skillData.skill_name}
                  name="skill_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Skill Name"
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-reading_level">Level</InputLabel>
                <Select
                  id="reading_level"
                  value={selectedLevel} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedLevel(event.target.value)} // Handle the change event
                >
                  <MenuItem value={"BEGINNER"}>BEGINNER</MenuItem>
                  <MenuItem value={"INTERMEDIATE"}>INTERMEDIATE</MenuItem>
                  <MenuItem value={"INTERMEDIATE"}>INTERMEDIATE</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-experience_years">
                  Years of Experience
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-experience_years"
                  value={skillData.experience_years}
                  name="experience_years"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Experience Years"
                  autoFocus
                  type="number"
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="last_used">Last Used</InputLabel>
                  <Select
                    fullWidth
                    id="last_used"
                    style={{ width: "200px" }}
                    value={skillData.last_used}
                    onChange={(e:any)=>handleInputChange(e)}
                    name="last_used"
                    placeholder="Last Used"
                  >
                    {years.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={untilNow}
                        onChange={handleUntillNowhange}
                        id="stillWorking-checkbox"
                      />
                    }
                    label="stillWorking"
                  />
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-description">
                  Description
                </InputLabel>
                <TextArea
                  id="personal-description"
                  value={skillData.description}
                  name="description"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Description"
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
          </Grid>
         
  
      </DialogContent>
      <Divider/>
      <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      Edit Skill
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default EditLanguageDialog;
