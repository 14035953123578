import ShopTwoIcon from "@mui/icons-material/ShopTwo";
import { FormattedMessage } from "react-intl";
import BusinessOutlinedIcon from "@mui/icons-material/BusinessOutlined";
import WhereToVoteIcon from "@mui/icons-material/WhereToVote";
// icons

const useStaticPage = () => {
  const icons = {
    ShopTwoIcon,
    BusinessOutlinedIcon,
    WhereToVoteIcon,
  };

  // ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

  const packages = {
    id: "static-page",
    title: (
      <FormattedMessage id="static-page">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "static-paget",
        title: <FormattedMessage id="static-page" />,
        type: "item",
        url: "/static-pages",
        icon: icons.ShopTwoIcon,
        breadcrumbs: false,
      },

    ],
  };
  return packages;
};

export default useStaticPage;
