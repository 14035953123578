const GET_VACANCIES_ORDERS = "vacancy_orders";

const ADD_VACANCIES_ORDERS = "vacancy_orders";

const EDIT_VACANCIES_ORDERS = "vacancy_orders/";

const GET_ONE_VACANCIES_ORDERS = "vacancy_orders/";

const DELETE_VACANCIES_ORDERS = "vacancy_orders/";

const FILTER_VACANCY_ORDER = "vacancy_orders/order/filter";

const MULTI_DELET = "vacancy_orders/remove/bulk_delete";

export default {
  GET_VACANCIES_ORDERS,

  ADD_VACANCIES_ORDERS,

  EDIT_VACANCIES_ORDERS,

  GET_ONE_VACANCIES_ORDERS,

  FILTER_VACANCY_ORDER,

  DELETE_VACANCIES_ORDERS,

  MULTI_DELET,
};
