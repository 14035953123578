import { useEffect, useState } from "react";

// material-ui
import {

  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,

  FormControl,

  Divider,
  DialogActions,
} from "@mui/material";

import { useAppDispatch } from "hooks";

import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";
import {
  editRecoResumes,
  getRecoResumes,
} from "store/actions/main/cv/recomendation/recommendation";
import { Switch } from "@mui/material";

// types

function EditRecoDialog({
  open,
  cvId,
  setOpen,
  language,
  setLanguage,
  resumeId,
  setLoading,
  loading,
  fetchGetOneReco,
}: {
  setLoading: any;
  loading: any;
  fetchGetOneReco: any;
  resumeId: any;
  cvId: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  language: any;
  setLanguage: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useAppDispatch();

  const [selectedStaus, setSelectedStatus] = useState(false);
  const [selectedRead, setSelectedRead] = useState(false);

  const fetchGetOne = () => {
    dispatch(getRecoResumes({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setLanguage(data);
      setSelectedStatus(language?.status || "");
      setSelectedRead(language?.read_by_admin || "");
    });
  };

  useEffect(() => {
    fetchGetOne();
  }, [dispatch]);

  const handleSave = async () => {
    // Log average value for debugging
    setLoading(true);
    dispatch(
      editRecoResumes({
        id: cvId,
        data: {
          resume_id: resumeId,
          recommender_name: language.recommender_name,
          position: language.position,
          company_name: language.company_name,
          job_title: language.job_title,
          mobile: language.mobile,
          email: language.email,
          status: selectedStaus,
          read_by_admin: selectedRead,
        },
      }),
    ).then((action) => {
      if (action.type === "recommendation/editRecoResumes/fulfilled") {
        openSnackbar({
          open: true,
          message: "Recommendation Updated successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);

        setOpen(false);
        setLoading(false);
        fetchGetOneReco();
        //  window.location.reload();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
      }
    });
  };
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setLanguage((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>Refences</DialogTitle>
        <Divider />

      <DialogContent>
        
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-recommender_name">
                  Refencer Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-recommender_name"
                  value={language?.recommender_name || ""}
                  name="recommender_name"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Recommender Name"
                  autoFocus
                  //inputRef={inputRef}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-position">
                  Job Position
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-position"
                  value={language?.position || ""}
                  name="position"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Position"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-company_name">
                  Company Name
                </InputLabel>
                <TextField
                  fullWidth
                  id="personal-company_name"
                  value={language?.company_name || ""}
                  name="company_name"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Company Name"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-job_title">Job Title</InputLabel>
                <TextField
                  fullWidth
                  id="personal-job_title"
                  value={language?.job_title || ""}
                  name="job_title"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Job Title"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-mobile">Mobile Number</InputLabel>
                <TextField
                  type="mobile"
                  fullWidth
                  value={language?.mobile || ""}
                  name="mobile"
                  // onBlur={handleBlur}
                  onChange={handleInputChange}
                  id="personal-email"
                  placeholder="Mobile Number"
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="personal-email">Email</InputLabel>
                <TextField
                  fullWidth
                  id="personal-email"
                  value={language?.email || ""}
                  name="email"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Email"
                />
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Stack spacing={1} style={{display:"flex",flexDirection:"row",alignItems:"center"}} >

              
                <InputLabel style={{width:"42%"}} htmlFor="personal-staus">Status</InputLabel>
                <FormControl style={{width:"42%"}} >
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={selectedStaus}
                            
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setSelectedStatus(checked);
                            }}
                          />
                        }
                        label={selectedStaus? "Active" : "Inactive"}
                      />
                    </FormControl>

                {/* <Select
                  id="staus"
                  value={selectedStaus} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedStatus(event.target.value)} // Handle the change event
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select> */}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1} style={{display:"flex",flexDirection:"row",alignItems:"center"}} >
             
                <InputLabel style={{width:"42%"}} htmlFor="personal-staus">Read By Admin</InputLabel>
                <FormControl style={{width:"42%"}} >
                      <FormControlLabel
                        control={
                          <Switch
                            id="customer-is_active"
                            checked={selectedRead}
                            
                            onChange={(event) => {
                              const checked = event.target.checked;
                              setSelectedRead(checked);
                            }}
                          />
                        }
                        label={selectedRead? "Active" : "Inactive"}
                      />
                    </FormControl>
                {/* <Select
                  id="read_by_admin"
                  value={selectedRead} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedRead(event.target.value)} // Handle the change event
                >
                  <MenuItem value={true}>true</MenuItem>
                  <MenuItem value={false}>false</MenuItem>
                </Select> */}
              </Stack>
            </Grid>
          </Grid>
         
     
      </DialogContent>
     
      <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                      Edit
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default EditRecoDialog;
