import { createAsyncThunk } from "@reduxjs/toolkit";
import { USERAXIOS } from "api";
import responseError from "utils/api/error-handling";
import jobSeekerUrl from "../../../../../utils/url/cv/jobSeekerProfile/jobSeekerProfile";
//axios

//get AllBasicInfo
export const getAllJobSeekerProfile = createAsyncThunk(
  "jobSeeker/getAllJobSeekerProfile",
  async (_, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(jobSeekerUrl.GET_ALL_JOB_SEEKERS);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//get One basicInfo

export const getOneJobSeekerProfile = createAsyncThunk(
  "jobSeeker/getOneJobSeekerProfile",
  async ({ id }: { id: any }, thunkAPI) => {
    try {
      const response = await USERAXIOS.get(
        `${jobSeekerUrl.GET_ONE_JOB_SEEKER}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//Edit One Category

export const editJobSeekerProfile = createAsyncThunk(
  "jobSeeker/editJobSeekerProfile",
  async ({ id, data }: { id: any; data: any }, thunkAPI) => {
    // Adjusted payload creator function
    // Destructure categoryId and data from payload
    try {
      const response = await USERAXIOS.post(
        `${jobSeekerUrl.EDIT_JOB_SEEKER}${id}`,
        data,
      );
      return response.data.result;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//add New basicIno
export const addNewJobSeekerProfile = createAsyncThunk(
  "jobSeeker/addNewJobSeekerProfile",
  async (data: any, thunkAPI) => {
    try {
      const response = await USERAXIOS.post(jobSeekerUrl.ADD_JOB_SEEKER, data);
      console.log(response.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);

//delete basic Info

export const deleteJobSeekerProfile = createAsyncThunk(
  "jobSeeker/deleteJobSeekerProfile",
  async ({ id }: { id: number }, thunkAPI) => {
    try {
      const response = await USERAXIOS.delete(
        `${jobSeekerUrl.DELETE_JOB_SEEKER}${id}`,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(responseError(error));
    }
  },
);
