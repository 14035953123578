import { useEffect, useState } from "react";

// material-ui
import {
  Autocomplete,

  Button,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControlLabel,
  DialogActions,
  Divider,
  Checkbox,
} from "@mui/material";

import { useAppDispatch } from "hooks";
import {
  editTargetJob,
  getOneTargetJob,
} from "store/actions/main/cv/requiredJob/requiredJob";
import { getAllJobAva } from "store/actions/main/basicInfo/jobAva";
import { getAllCountries } from "store/actions/main/countries";
import { getAllCategories } from "store/actions/main/categories";
import { getAllJobType } from "store/actions/main/basicInfo/jobTypes";
import TextArea from "antd/es/input/TextArea";
import { openSnackbar } from "api/snackbar";
import { SnackbarProps } from "types/snackbar";

// types

function EditReqJobDialog({
  open,
  cvId,
  setOpen,
  fetchData,

  setLoading,
  jobData,
  setjobData,
  resumeId,
  fetchGetOneReq,
}: {
  fetchGetOneReq: any;
  setShowTable: any;
  fetchData: any;
  loading: any;
  setLoading: any;
  resumeId: any;
  cvId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  jobData: any;
  setjobData: any;
}) {
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedCountry, setSelectedCountry] = useState([]);

  const dispatch = useAppDispatch();
  const fetchGetOne = () => {
    if(cvId!==undefined){
      console.log(cvId,"CVID")
    dispatch(getOneTargetJob({ id: cvId })).then((action) => {
      const data = action.payload["result: "];
      setjobData(data);
      setSelectedJobAvaId(jobData?.job_availability_id || "");
      setSelectedCurrency(jobData?.currency || "");
      setConfidential(jobData?.confidential || "");
      setSelectedCountries(jobData?.countries || []);
      setSelectedCategories(jobData?.categories || []);
      setSelectedJobTypes(jobData?.job_types || []);
    });}
  };

  const handleSave = () => {
    // Log average value for debugging
    const selectedCountryIds =
      selectedCountries?.map((country) => country.id) || [];
    const selectedcatIds = selectedCategories?.map((cat) => cat.id) || [];
    const selectedJobIds = selectedJobTypes?.map((job) => job.id) || [];
    dispatch(
      editTargetJob({
        id: cvId,
        data: {
          job_seeker_profile_id: resumeId,
          job_availability_id: selectedJobAvaId,
          goals: jobData.goals,
          salary_expected: jobData.salary_expected,
          currency: selectedCurrence,
          confidential: confidential,
          country_ids: selectedCountryIds,
          job_type_ids: selectedJobIds,
          category_ids: selectedcatIds,
        },
      }),
    ).then((action) => {
      if (action.type === "reqJob/editTargetJob/fulfilled") {
        openSnackbar({
          open: true,
          message: "New Target Job Added successfully",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "success",
          },
        } as SnackbarProps);
        fetchData();
        setOpen(false);
        fetchGetOneReq();
      } else {
        openSnackbar({
          open: true,
          message: "some thing went wrong",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          variant: "alert",
          alert: {
            color: "error",
          },
        } as SnackbarProps);
        setOpen(false);
        setLoading(false);
      }
    });
  };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setjobData((prevFormData: any) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const [selectedJobAva, setSelectedJobAva] = useState([]);
  const [selectedJobAvaId, setSelectedJobAvaId] = useState(""); // State to store the selected ID
  const [selectedCurrence, setSelectedCurrency] = useState("");

  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [confidential, setConfidential] = useState(jobData?.confidential);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const handleJobAvaChange = (event) => {
    const selectedId = event.target.value; // Get the selected ID
    setSelectedJobAvaId(selectedId);
    // Set the selected ID to state
    const selectedItem = selectedJobAva.find((item) => item.id === selectedId);
    if (selectedItem) {
     
    }
  };

  const fetchJobAvaData = () => {
    dispatch(getAllJobAva({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        // Extract user names
        setSelectedJobAva(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchCountryaData = () => {
    dispatch(getAllCountries({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedCountry(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };

  const fetchCategoryData = () => {
    dispatch(getAllCategories({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedCategory(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const fetchJobTypeData = () => {
    dispatch(getAllJobType({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;

        // Extract user names
        setSelectedJobType(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  };
  const handleCountryChange = ( value) => {
    setSelectedCountries(value); // Update selected countries
  };
  const handleCategoryChange = ( value) => {
    setSelectedCategories(value); // Update selected countries
  };
  const handleJobTypeChange = ( value) => {
    setSelectedJobTypes(value); // Update selected countries
  };
  useEffect(() => {
    fetchGetOne();
    fetchJobAvaData();
    fetchCountryaData();
    fetchCategoryData();
    fetchJobTypeData();
  }, [dispatch]);
  const handleConfidentialChange = (event) => {
    setConfidential(event.target.checked); // Set the value of confidentiality based on checkbox state
  };
  return (
    <Dialog open={open} onClose={handleClose}>
     
        <DialogTitle style={{textAlign:'center', fontSize:'22px', fontWeight:'bold', color:'rgb(22, 119, 255)', marginBottom:'10px', }}>
          Edit Targeted Job
        </DialogTitle>
        <Divider />
 

      <DialogContent>
      
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="job_availability_id">
                  Job Availibility
                </InputLabel>
                <Select
                  id="job_availability_id"
                  value={selectedJobAvaId} // Set the selected ID as the value of the Select component
                  onChange={handleJobAvaChange} // Handle the change event
                >
                  {selectedJobAva.map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {user.name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="salary_expected">Job Types</InputLabel>
              <Stack direction={"row"}>
                <Autocomplete
                  style={{ width: "100%", marginTop: "10px" }}
                  multiple // Enable multi-select
                  id="job_types-autocomplete"
                  options={selectedJobType}
                  getOptionLabel={(option) => option.name} // Customize option label
                  value={(selectedJobTypes && selectedJobTypes) || []}
                  onChange={handleJobTypeChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Job Type"
                      placeholder="Select Job Types"
                    />
                  )}
                />
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="goals">Goals</InputLabel>
                <TextArea
                  id="goals"
                  value={jobData?.goals || ""}
                  name="goals"
                  //onBlur={handleBlur}
                  onChange={handleInputChange}
                  placeholder="Goals"
                />
              </Stack>
            </Grid> */}

            <Grid item xs={12} sm={6}>
              <Stack direction={"row"}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="salary_expected">
                    Salary Expected
                  </InputLabel>
                  <TextField
                    fullWidth
                    id="salary_expected"
                    type="number"
                    value={jobData?.salary_expected || ""}
                    name="salary_expected"
                    //onBlur={handleBlur}
                    onChange={handleInputChange}
                    placeholder="Salary Expected"
                  />
                </Stack>
                <Stack
                  spacing={1}
                  display={"flex"}
                  alignItems={"end"}
                  justifyContent={"end"}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={confidential}
                        onChange={handleConfidentialChange}
                        id="confidential-checkbox"
                      />
                    }
                    label="Confidential"
                  />
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack spacing={1}>
                <InputLabel htmlFor="currency">Currency</InputLabel>
                <Select
                  id="currency"
                  value={selectedCurrence} // Set the selected ID as the value of the Select component
                  onChange={(event) => setSelectedCurrency(event.target.value)} // Handle the change event
                >
                  <MenuItem value={"DOLLAR"}>DOLLAR</MenuItem>
                  <MenuItem value={"SYP"}>SYP</MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="salary_expected">Countries</InputLabel>
              <Stack direction={"row"}>
                <Autocomplete
                  style={{ width: "100%", marginTop: "10px" }}
                  multiple // Enable multi-select
                  id="countries-autocomplete"
                  options={selectedCountry}
                  getOptionLabel={(option) => option.name} // Customize option label
                  value={(selectedCountries && selectedCountries) || []}
                  onChange={handleCountryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Countries"
                      placeholder="Select Countries"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="salary_expected">Categories</InputLabel>
              <Stack direction={"row"}>
                <Autocomplete
                  style={{ width: "100%", marginTop: "10px" }}
                  multiple // Enable multi-select
                  id="categories-autocomplete"
                  options={selectedCategory}
                  getOptionLabel={(option) => option.name} // Customize option label
                  value={(selectedCategories && selectedCategories) || []}
                  onChange={handleCategoryChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Select Categories"
                      placeholder="Select Categories"
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack spacing={1}>
                <InputLabel htmlFor="goals">Goals</InputLabel>
                <TextArea
                  id="goals"
                  value={jobData?.goals || ""}
                  name="goals"
                  rows={3}
                  //onBlur={handleBlur}
                  onChange={()=>handleInputChange}
                  placeholder="Goals"
                />
              </Stack>
            </Grid>
           
          </Grid>
         
    
      </DialogContent>
      <Divider />
            <DialogActions sx={{ p: 2.5 }}>
              <Grid container justifyContent="center" alignItems="center">
                  <Stack direction="row" spacing={2} sx={{ width: 1 }}>
                    <Grid item md={2}/>
                    <Grid item md={4}>
                      <Button fullWidth type="submit" onClick={handleSave} variant="contained">
                       Edit
                      </Button>
                    </Grid>
                    <Grid item md={4}>
                      <Button  fullWidth color="secondary" variant="outlined" onClick={handleClose}>
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2}/>
                  </Stack>
              </Grid>
            </DialogActions>
    </Dialog>
  );
}

export default EditReqJobDialog;
