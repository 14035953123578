import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import MainCard from "components/MainCard";
import { useState } from "react";
//import DeletEducation from "./deleteEducationDialog";
import { useAppDispatch } from "hooks";

import DeletReqJob from "./deleteReqJobDialog";

import EditReqJobDialog from "./editDialodReqJob";

import { Job } from "model/cv/targeJob/targetJob";
import Loader from "components/Loader";
import { getOnebasicInfo } from "store/actions/main/cv/basicinfo/basicInfo";

export function TableReqJob({
  resumeId,
  reqJob,
  setLoading,
  loading,
  setShowTable,
  fetchGetOneReq,
}: {
  fetchGetOneReq: any;
  setShowTable: any;
  loading: any;
  setLoading: any;
  resumeId: number;
  reqJob: any;
}) {
  const dispatch = useAppDispatch();


  const resumeTraget = reqJob?.resume_target_job;

  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const fetchData = () => {
    dispatch(getOnebasicInfo({ cvId: resumeId }));
  };
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [jobData, setjobData] = useState<Job>();
  const [openDialog, setOpenDialog] = useState(false);

  const fetchEducationData = async (id: any) => {};

  const handleEdit = (id: number) => {
    setDeletId(id);
    setOpenDialog(true);
    fetchEducationData(id);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };

  return (
    <>
      <Stack>
        {loading ? (
          <Loader />
        ) : (
          <Grid width={"57vw"} sx={{ mt: "10px" }}>
            <MainCard
              title="Targeted Job"
              headerActions={
                <Stack
                  direction={"row"}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <EditOutlinedIcon
                      sx={{
                        width: 20,
                        height: 20,
                        marginRight: 2,
                        cursor: "pointer",
                        color: "#4a934a",
                      }}
                      onClick={() => handleEdit(reqJob.id)}
                    />
                    <DeleteOutlinedIcon
                      sx={{
                        width: 20,
                        height: 20,
                        cursor: "pointer",
                        color: "#ff3333",
                        marginRight: "20px",
                      }}
                      onClick={() => handleDelete(reqJob.id)}
                    />
                  </div>
                  {/* Add additional controls here */}
                </Stack>
              }
            >
              <List>
                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Availability to Join a job
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>
                          {resumeTraget?.job_availability_id || ""}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Targeted Job type
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>
                          {resumeTraget?.job_types.map((country, index) => (
                            <span key={index}>
                              {country.name}
                              {index !== resumeTraget.job_types.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Targeted Job Category
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>
                          {resumeTraget?.categories.map((country, index) => (
                            <span key={index}>
                              {country.name}
                              {index !== resumeTraget.categories.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Targeted Job Location
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>
                          {resumeTraget?.countries.map((country, index) => (
                            <span key={index}>
                              {country.name}
                              {index !== resumeTraget.countries.length - 1
                                ? ", "
                                : ""}
                            </span>
                          ))}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Desired net Salary
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>
                          {resumeTraget?.salary_expected || ""}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>

                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Currency
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>{resumeTraget?.currency || ""}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem divider={!matchDownMD}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Confidetial
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>
                          {resumeTraget?.confidetial === false
                            ? "false"
                            : "true"}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
                <ListItem>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} marginBottom={"15px"}>
                      <Stack spacing={0.5}>
                        <Typography
                          color="secondary"
                          sx={{ fontWeight: "bold", color: "black" }}
                        >
                          Goals
                        </Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Stack spacing={0.5}>
                        <Typography>{resumeTraget?.goals || ""}</Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </MainCard>
          </Grid>
        )}
      </Stack>
      {open && (
        <DeletReqJob
          open={open}
          setOpen={setOpen}
          id={deletId}
          fetchGetOneReq={fetchGetOneReq}
        />
      )}
      {openDialog && (
        <EditReqJobDialog
          fetchGetOneReq={fetchGetOneReq}
          setShowTable={setShowTable}
          fetchData={fetchData}
          loading={loading}
          setLoading={setLoading}
          cvId={deletId}
          resumeId={resumeId}
          open={openDialog}
          setOpen={setOpenDialog}
          jobData={jobData}
          setjobData={setjobData}
        />
      )}
    </>
  );
}
