import { useEffect, useMemo, useState } from "react";

// material-ui
import { Box, Modal, Stack } from "@mui/material";

// project-imports
import FormCustomerAdd from "./FormBannerAdd";
import MainCard from "components/MainCard";
import SimpleBar from "components/third-party/SimpleBar";
import CircularWithPath from "components/@extended/progress/CircularWithPath";

// types

import FormCountryAdd from "./FormBannerAdd";
import { CountryList, UserList } from "types/customer";
import FormUserAdd from "./FormBannerAdd";
import { banners } from "model/ads";
import FormBannerAdd from "./FormBannerAdd";
import { getAllCompanies } from "store/actions/main/companies";
import { getAllVacancies } from "store/actions/main/packages/vacanciesPackages";
import { useAppDispatch } from "hooks";
import { getAllVacanciesOrder } from "store/actions/main/order/vacancyOrder";

interface Props {
  open: boolean;
  modalToggler: (state: boolean) => void;
  customer?: banners | null;
  id: number;
  fetchData: any;
}

// ==============================|| CUSTOMER ADD / EDIT ||============================== //

const BannerModal = ({
  fetchData,
  open,
  modalToggler,
  customer,
  id,
}: Props) => {
  const closeModal = () => modalToggler(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [userName, setUserName] = useState();
  const [Packages, setPackages] = useState();
  const dispatch = useAppDispatch();
  const fetchCompanyData = () => {
    setLoading(true);
    dispatch(getAllCompanies({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        setLoading(false);
        // Extract user names
        setUserName(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching users:", error);
      });
  };

  const fetchDataPackages = () => {
    setLoading(true);
    dispatch(getAllVacanciesOrder({ pageNumber: 1 }))
      .then((action) => {
        const { data } = action.payload;
        setLoading(false);
        // Extract user names
        setPackages(data); // Set user names as options for autocomplete
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching users:", error);
      });
  };
  useEffect(() => {
    fetchCompanyData();
    fetchDataPackages();
  }, [open, dispatch, id]);
  const customerForm = useMemo(
    () =>
      !loading && (
        <FormBannerAdd
          userName={userName}
          id={id}
          loading={loading}
          Packages={Packages}
          setLoading={setLoading}
          fetchData={fetchData}
          customer={customer || null}
          closeModal={closeModal}
        />
      ),
    // eslint-disable-next-line
    [customer, loading, Packages, userName],
  );

  return (
    <>
      {open && (
        <Modal
          open={open}
          onClose={closeModal}
          aria-labelledby="modal-customer-add-label"
          aria-describedby="modal-customer-add-description"
          sx={{
            "& .MuiPaper-root:focus": {
              outline: "none",
            },
          }}
        >
          <MainCard
            sx={{
              width: `calc(100% - 48px)`,
              minWidth: 340,
              maxWidth: 700,
              height: "auto",
              maxHeight: "calc(100vh - 48px)",
            }}
            modal
            content={false}
          >
            <SimpleBar
              sx={{
                maxHeight: `calc(100vh - 48px)`,
                "& .simplebar-content": {
                  display: "flex",
                  flexDirection: "column",
                },
              }}
            >
              {loading ? (
                <Box sx={{ p: 5 }}>
                  <Stack direction="row" justifyContent="center">
                    <CircularWithPath />
                  </Stack>
                </Box>
              ) : (
                customerForm
              )}
            </SimpleBar>
          </MainCard>
        </Modal>
      )}
    </>
  );
};

export default BannerModal;
