const GET_ALL_BANKS = "banks";

const ADD_BANKS = "banks";

const GET_ONE_BANKS = "banks/";

const EDIT_BANKS = "banks/";

const DELETE_BANKS = "banks/";

const MULTI_DELET_BANKS = "banks/remove/bulk_delete";

export default {
  GET_ALL_BANKS,
  GET_ONE_BANKS,
  ADD_BANKS,
  EDIT_BANKS,
  DELETE_BANKS,
  MULTI_DELET_BANKS,
};
