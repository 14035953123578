import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  EducationResponse,
  EducationState,
} from "model/cv/education/education";

import {
  addNewEducation,
  deleteEducation,
  editEducation,
  getAllEducation,
  getOneEducation,
} from "store/actions/main/cv/education/education";

const initialState: EducationState = {
  result: [],
  isOk: false,
  message: "",
  confirmCode: "",
};

const educationSlice = createSlice({
  name: "education",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllEducation.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllEducation.fulfilled.type,
        (state, action: PayloadAction<EducationResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewEducation.fulfilled.type,
        (state, action: PayloadAction<EducationResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewEducation.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneEducation.fulfilled.type,
        (state, action: PayloadAction<EducationResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneEducation.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editEducation.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editEducation.fulfilled.type,
        (state, action: PayloadAction<EducationResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteEducation.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteEducation.fulfilled.type,
        (state, action: PayloadAction<EducationResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = educationSlice.actions;

export default educationSlice;
