import { useEffect, useState } from "react";

// material-ui
import {
  FormControl,
  Grid,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import { TableState, Updater } from "@tanstack/react-table";

interface TablePaginationProps {
  setPageSize: (updater: Updater<number>) => void;
  setPageIndex: (updater: Updater<number>) => void;
  getState: () => TableState;
  handlePageChange: any;

  getPageCount: () => any;
  currentPage: any; // Add currentPage to the interface
  initialPageSize?: number;
}

// ==============================|| TABLE PAGINATION ||============================== //

const TablePagination = ({
  getPageCount,
  setPageIndex,
  setPageSize,
  getState,

  currentPage,
  handlePageChange,
  initialPageSize,
}: TablePaginationProps) => {
  // @ts-ignore
  const [open, setOpen] = useState(false);
  let options: number[] = [10, 25, 50, 100];
console.log(open)
  if (initialPageSize) {
    options = [...options, initialPageSize]
      .filter(
        (item, index) => [...options, initialPageSize].indexOf(item) === index,
      )
      .sort(function (a, b) {
        return a - b;
      });
  }

  // eslint-disable-next-line
  useEffect(() => setPageSize(initialPageSize || 10), []);

  

  const handleChange = (event: SelectChangeEvent<number>) => {
    setPageSize(Number(event.target.value));
  };

  return (
    <Grid
      spacing={1}
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: "auto" }}
    >
      <Grid item>
        <Stack direction="row" spacing={1} alignItems="center">
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" color="secondary">
              Row per page
            </Typography>
            <FormControl sx={{ m: 1 }}>
              <Select
                value={getState().pagination.pageSize}
                onChange={handleChange}
                size="small"
                sx={{ "& .MuiSelect-select": { py: 0.75, px: 1.25 } }}
              >
                {options.map((option: number) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
          <Typography variant="caption" color="secondary">
            Go to
          </Typography>
          <TextField
            size="small"
            type="number"
            value={getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              setPageIndex(page);
            }}
            sx={{
              "& .MuiOutlinedInput-input": { py: 0.75, px: 1.25, width: 36 },
            }}
          />
        </Stack>
      </Grid>
      <Grid item sx={{ mt: { xs: 2, sm: 0 } }}>
        <Pagination
          sx={{ "& .MuiPaginationItem-root": { my: 0.5 } }}
          count={getPageCount()}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          variant="combined"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

export default TablePagination;
