import {  RouterProvider } from "react-router-dom";

// project import
import router from "routes";
import ThemeCustomization from "themes";

// import RTLLayout from 'components/RTLLayout';
import ScrollTop from "components/ScrollTop";
import Snackbar from "components/@extended/Snackbar";
import Notistack from "components/third-party/Notistack";
import { IntlProvider } from 'react-intl';
// auth-provider
import { JWTProvider as AuthProvider } from "contexts/JWTContext";
import { Provider } from "react-redux";
import store from "store";

import messages_en from '../src/utils/locales/en.json';
import messages_ar from '../src/utils/locales/ar.json';

import RTLLayout from "components/RTLLayout";
import Locales from "./components/Locales";

// ==============================|| APP - THEME, ROUTER, LOCAL ||============================== //
const messages = {
  'ar': messages_ar,
  'en':messages_en

  
};


const language = 'ar';

const App = () => (

  <ThemeCustomization>
     <RTLLayout> 
    <Locales>
      <ScrollTop>
        <AuthProvider>
          <>
            <Provider store={store}>
              <Notistack>
                <RouterProvider router={router} />
                <Snackbar />
              </Notistack>
            </Provider>
          </>
        </AuthProvider>
      </ScrollTop>
      </Locales>
   </RTLLayout>
  </ThemeCustomization>
);

export default App;
