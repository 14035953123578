const GET_ALL_SKILLS = "resume/skills";

const ADD_SKILLS = "resume/skills";

const EDIT_SKILLS = "resume/skills/";

const GET_ONE_SKILL = "resume/skills/";

const DELETE_SKILL = "resume/skills/";

const MULTI_DELET_SKILLS = "resume/skills/remove/bulk_delete";

export default {
  GET_ALL_SKILLS,
  ADD_SKILLS,
  EDIT_SKILLS,
  GET_ONE_SKILL,
  DELETE_SKILL,
  MULTI_DELET_SKILLS,
};
