import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutlined as DeleteOutlinedIcon,
} from "@mui/icons-material";
import {
  Grid,
  Typography,
  Stack,
  List,
  useMediaQuery,
  ListItem,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

import MainCard from "components/MainCard";
import { useEffect, useState } from "react";

import { useAppDispatch } from "hooks";

import EditQuestionDialog from "./editDialogQuestion";
import DeletQuestion from "./deleteQuestionDialog";
import Loader from "components/Loader";
import { AnswerModel } from "model/answer";
import { getOneAnswer } from "store/actions/main/basicInfo/answer";

export function TableQuestion({
  cvId,
  loadingQues,
  setLoadingQues,
  coursCertificates,
  fetchGetOneQues,
}: {
  fetchGetOneQues: any;
  loadingQues: any;
  setLoadingQues: any;
  cvId: number;
  coursCertificates: any;
}) {
  const languageArray = coursCertificates?.resume_mini_interview_answers || [];
  // Filter education certificates based on cvId

  const matchDownMD = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [deletId, setDeletId] = useState(0);
  const [open, setOpen] = useState(false);
  const [ques, setQues] = useState<AnswerModel>();
  const [openDialogQues, setOpenDialogQues] = useState(false);

  const dispatch = useAppDispatch();
  const fetchCourseData = async (id: any) => {
    try {
      const action = await dispatch(getOneAnswer({ id: id })); // Assume getEducationById is your API function
      const data = action.payload["result: "]; // Extract payload from the action
      setQues(data); // Set fetched data to state
    } catch (error) {
      console.error("Error fetching education data:", error);
    }
  };

  const handleEdit = (id: number) => {
    setDeletId(id);
    fetchCourseData(id);
    setOpenDialogQues(true);
  };
  const handleDelete = (id: number) => {
    setDeletId(id);
    setOpen(true);
  };
  useEffect(() => {
    const fetchName = async () => {
      
  

      // Process availability names

      // Combine names into a single string or format as needed
    };

    fetchName();
  }, [coursCertificates]);
  return (
    <>
      {loadingQues ? (
        <Loader />
      ) : (
        <Stack>
          {languageArray.map((course: any, index: number) => (
            <Grid width={"57vw"} sx={{ mt: "10px" }}>
              <MainCard
                title="Mini Interview"
                headerActions={
                  <Stack
                    direction={"row"}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <EditOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          marginRight: 2,
                          cursor: "pointer",
                          color: "#4a934a",
                        }}
                        onClick={() => handleEdit(course.id)}
                      />
                      <DeleteOutlinedIcon
                        sx={{
                          width: 20,
                          height: 20,
                          cursor: "pointer",
                          color: "#ff3333",
                          marginRight: "20px",
                        }}
                        onClick={() => handleDelete(course.id)}
                      />
                    </div>
                    {/* Add additional controls here */}
                  </Stack>
                }
              >
                <List>
                  <ListItem divider={!matchDownMD}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Question</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography>{course.question_text}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                  <ListItem>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography color="secondary">Answer</Typography>
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Stack spacing={0.5}>
                          <Typography>{course.answer}</Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </ListItem>
                </List>
              </MainCard>
            </Grid>
          ))}
        </Stack>
      )}

      {open && (
        <DeletQuestion
          open={open}
          setOpen={setOpen}
          id={deletId}
          fetchDataQues={fetchGetOneQues}
        />
      )}
      {openDialogQues && (
        <EditQuestionDialog
          setLoadingQues={setLoadingQues}
          loadingQues={loadingQues}
          fetchDataQues={fetchGetOneQues}
          resumeId={cvId}
          cvId={deletId}
          open={openDialogQues}
          setOpen={setOpenDialogQues}
          courseData={ques}
          setCourseData={setQues}
        />
      )}
    </>
  );
}
