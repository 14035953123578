// assets
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import WorkHistoryOutlinedIcon from "@mui/icons-material/WorkHistoryOutlined";
import { FormattedMessage } from "react-intl";
const useWorkItems = () => {
  const icons = {
    WorkOutlineOutlinedIcon,
    WorkHistoryOutlinedIcon,
  };

  // ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

  const work = {
    id: "vacancy-management",
    title: (
      <FormattedMessage id="vacancy-management">
        {(text) => (
          <span style={{ fontSize: "14px", fontWeight: "bold" }}>{text}</span>
        )}
      </FormattedMessage>
    ),
    type: "group",
    children: [
      {
        id: "employment-opportunity",
        title: <FormattedMessage id="employment-opportunity" />,
        type: "item",
        url: "/employment-opportunity",
        icon: icons.WorkOutlineOutlinedIcon,
        breadcrumbs: false,
      },
      {
        id: "incoming-job-applications",
        title: <FormattedMessage id="incoming-job-applications" />,
        type: "item",
        url: "/incoming-job-applications",
        icon: icons.WorkHistoryOutlinedIcon,
        breadcrumbs: false,
      },
    ],
  };
  return work;
};

export default useWorkItems;
