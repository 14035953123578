import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { MailResponse, MailState } from "model/basicInfo";

import {
  getAllMails,
  getOneMail,
  addNewMail,
  deleteMail,
  editMail,
  MultiDeletMails,
} from "store/actions/main/mail";

const initialState: MailState = {
    result: [],
    isOk: false,
    message: "",
    confirmCode: "",
};

const MailSlice = createSlice({
  name: "basicInfo",
  initialState: initialState,
  reducers: {
    resetStateStatus: (state) => {
      state.isOk = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllMails.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getAllMails.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewMail.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        addNewMail.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        getOneMail.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        getOneMail.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editMail.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        editMail.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        deleteMail.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        deleteMail.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      )
      .addCase(
        MultiDeletMails.rejected.type,
        (state, action: PayloadAction<string>) => {
          state.isOk = false;
          state.message = action.payload;
        },
      )
      .addCase(
        MultiDeletMails.fulfilled.type,
        (state, action: PayloadAction<MailResponse[]>) => {
          state.result = action.payload;
        },
      );
  },
});

export const { resetStateStatus } = MailSlice.actions;

export default MailSlice;
