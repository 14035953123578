const GET_ALL_LANGUAGES = "resume/languages";

const ADD_LANGUAGES = "resume/languages";

const EDIT_LANGUAGES = "resume/languages/";

const GET_ONE_LANGUAGES = "resume/languages/";

const DELETE_LANGUAGES = "resume/languages/";

const MULTI_DELET_LANGUAGES = "rresume/languages/remove/bulk_delete";

export default {
  GET_ALL_LANGUAGES,
  ADD_LANGUAGES,
  EDIT_LANGUAGES,
  GET_ONE_LANGUAGES,
  DELETE_LANGUAGES,
  MULTI_DELET_LANGUAGES,
};
