const BASIC_INFO = "resumes";

const BASIC_INFO_ONE = "resumes/";

const ACTIVATE_CV = "resumes/activate/";

const DEACTIVATE_CV = "resumes/deactivate/";

const MULTI_DELETE_BASIC_INFO = "resumes/remove/bulk_delete";

const TOTAL_CV = "resumes/resume/total";

const GET_ALL_RESUME_ACTIVE = "resume_reports/active";

const GET_ALL_RESUME_INACTIVE = "resume_reports/inactive";

const READ_RESUME = "resumes/read/";

const UNREAD_RESUME = "resumes/unread/";
const RESUME_STATISTICS = "resume_statistics/resume/";

export default {
  BASIC_INFO,
  TOTAL_CV,
  BASIC_INFO_ONE,
  ACTIVATE_CV,
  DEACTIVATE_CV,
  MULTI_DELETE_BASIC_INFO,
  GET_ALL_RESUME_ACTIVE,
  GET_ALL_RESUME_INACTIVE,
  READ_RESUME,
  UNREAD_RESUME,
  RESUME_STATISTICS
};
