import { useState } from "react";

// material-ui
import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import PhoneIcon from "@mui/icons-material/Phone";
import LoginIcon from "@mui/icons-material/Login";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import AppsIcon from "@mui/icons-material/Apps";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
  handleLogout: () => void;
}

const CompanyProfileTab = () => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement>,
    index: number,
  ) => {
    setSelectedIndex(index);
  };

  return (
    <List
      component="nav"
      sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
    >
      <ListItemButton
        selected={selectedIndex === 0}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 0)
        }
      >
        <ListItemIcon>
          <BusinessCenterOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Vacancies Packages" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 1}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 1)
        }
      >
        <ListItemIcon>
          <BusinessCenterOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="Resume Packages" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 2}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 2)
        }
      >
        <ListItemIcon>
          <PhoneIcon />
        </ListItemIcon>
        <ListItemText primary="Contacts" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 3}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 3)
        }
      >
        <ListItemIcon>
          <LoginIcon />
        </ListItemIcon>
        <ListItemText primary="Last Signed" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 4}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 4)
        }
      >
        <ListItemIcon>
          <BusinessCenterOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="All Job Vacancies" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 5}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 5)
        }
      >
        <ListItemIcon>
          <EventAvailableIcon />
        </ListItemIcon>
        <ListItemText primary="ONLINE Job Vacancies" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 6}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 6)
        }
      >
        <ListItemIcon>
          <PendingActionsIcon />
        </ListItemIcon>
        <ListItemText primary="PENDING Job Vacancies" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 7}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 7)
        }
      >
        <ListItemIcon>
          <RemoveDoneIcon />
        </ListItemIcon>
        <ListItemText primary="EXPIRED Job Vacancies" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 8}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 8)
        }
      >
        <ListItemIcon>
          <ApartmentIcon />
        </ListItemIcon>
        <ListItemText primary="Company Classifieds" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 9}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 9)
        }
      >
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary="Applications" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 10}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 10)
        }
      >
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary="All Company Applications" />
      </ListItemButton>
      <ListItemButton
        selected={selectedIndex === 11}
        onClick={(event: React.MouseEvent<HTMLDivElement>) =>
          handleListItemClick(event, 11)
        }
      >
        <ListItemIcon>
          <VpnKeyIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
    </List>
  );
};

export default CompanyProfileTab;
