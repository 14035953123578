import { useEffect, useState, ChangeEvent } from "react";
import { Link } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Divider,
  FormLabel,
  Grid,
  TextField,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";

// project import
import MainCard from "components/MainCard";
import Avatar from "components/@extended/Avatar";

import { facebookColor, linkedInColor, twitterColor } from "config";

// assets
import {
  FacebookFilled,
  LinkedinFilled,
  MoreOutlined,
  TwitterSquareFilled,
  CameraOutlined,
} from "@ant-design/icons";
import IconButton from "components/@extended/IconButton";

// types
import { ThemeMode } from "types/config";
import CompanyProfileTab from "./CompanyProfileTab";

const avatarImage = require.context("assets/images/users", true);

// ==============================|| USER PROFILE - TAB CONTENT ||============================== //

interface Props {
  focusInput: () => void;
}

const ComapnyProfileTabs = () => {
  const theme = useTheme();
  const [selectedImage, setSelectedImage] = useState<File | undefined>(
    undefined,
  );
  //const [avatar, setAvatar] = useState<string | undefined>(avatarImage(''));

  useEffect(() => {
    if (selectedImage) {
      //  setAvatar(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const [anchorEl, setAnchorEl] = useState<
    Element | (() => Element) | null | undefined
  >(null);
  const open = Boolean(anchorEl);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement> | undefined,
  ) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack style={{ marginTop: "10px", width: "37vh" }}>
      <MainCard>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <CompanyProfileTab />
          </Grid>
        </Grid>
      </MainCard>
    </Stack>
  );
};

export default ComapnyProfileTabs;
