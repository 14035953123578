//Store
import { configureStore, AnyAction, ThunkAction } from "@reduxjs/toolkit";
//Slices
import companySlice from "./slices/main/companies";
import userSlice from "./slices/main/user";
import countrySlice from "./slices/main/countries";
import categorySlice from "./slices/main/categories";
import basicInfoSlice from "./slices/main/cv/basicInfo/basicInfo";
import educationSlice from "./slices/main/cv/education/education";
import JobSeekerProfileSlice from "./slices/main/cv/jobSeekerProfile/jobSeekerProfile";
import authenticationSlice from "./slices/main/sign";
import reqJobSlice from "./slices/main/cv/requiredJob/requiredJob";
import experienceSlice from "./slices/main/cv/experience/experience";
import courseSlice from "./slices/main/cv/courses/courses";
import resumePackageSlice from "./slices/main/packages/resumesPackeges";
import VacancyPackageSlice from "./slices/main/packages/vacanciesPackages";
import rolesSlice from "./slices/main/roles";
import resumeSlice from "./slices/main/cv/resumes";
import MailSlice from "./slices/main/basicInfo/mailTemplate";
import AdminNoteSlice from "./slices/main/basicInfo/adminNote";

//Store
const store = configureStore({
  reducer: {
    company: companySlice.reducer,
    user: userSlice.reducer,
    country: countrySlice.reducer,
    categories: categorySlice.reducer,
    basicInfo: basicInfoSlice.reducer,
    education: educationSlice.reducer,
    jobSeekerProfile: JobSeekerProfileSlice.reducer,
    authentication: authenticationSlice.reducer,
    reqJob: reqJobSlice.reducer,
    experience: experienceSlice.reducer,
    course: courseSlice.reducer,
    resumePackage: resumePackageSlice.reducer,
    vacancyPackage: VacancyPackageSlice.reducer,
    role: rolesSlice.reducer,
    resume: resumeSlice.reducer,
    mail:MailSlice.reducer,
    adminNote: AdminNoteSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

//Export types: state and dispatch
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, AnyAction>;

//Export store
export default store;
